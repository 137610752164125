import React from 'react'
import logomain from '../../images/mysic-lungo2.png'
import CheckIcon from '@mui/icons-material/Check';

const PreLoginMobile = () => {
  return (
    <div style={{ height: '100vh', display:'flex',flexDirection:'column',overflowX:'hidden'}}>
         {/* CONTENUTO SCROLLABILE */}
      <div style={{
        flex: 1,  // Occupa tutto lo spazio disponibile
        overflowY: 'auto',  // Abilita lo scroll verticale se necessario
        WebkitOverflowScrolling: 'touch'  // Scroll fluido su iOS
      }}>
<div style={{backgroundColor: 'white',
        backgroundImage: `
          radial-gradient(circle at 20% 20%, rgba(255, 99, 71, 0.2) 10%, transparent 30%),
          radial-gradient(circle at 80% 40%, rgba(30, 144, 255, 0.2) 10%, transparent 30%),
          radial-gradient(circle at 60% 80%, rgba(128, 128, 128, 0.2) 10%, transparent 30%),
          radial-gradient(circle at 40% 70%, rgba(100, 149, 237, 0.2) 10%, transparent 30%)
        `,
        backgroundSize: 'cover', width:'100vw',overflowX:'hidden',minHeight:'50vh'}}>
            <div style={{width:'fit-content',marginLeft:'auto',marginRight:'auto',marginTop:'10vh',alignContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
            <img src={logomain} alt='logo_mysic' style={{width:'50vw'}}></img>
            <h4 style={{fontFamily:'Poppins',color:'#363949',fontWeight:'600',marginBottom:0,marginTop:0,fontSize:'20px',textAlign:'center'}}>Sicurezza in Cloud 365 Giorni</h4>
            <button className='main-button' style={{minWidth:'20vw',marginTop:'40px'}}>Versione Mobile Disponibile a Breve</button>

            </div>
        </div>
    
    <div style={{flexDirection:'column',alignItems:'center',justifyContent:'center',display:'flex',borderBottom:'1px solid lightgrey',minHeight:'50vh'}}>
    <h4 style={{fontFamily:'Poppins',color:'#14213d',fontSize:'20px',width:'85%',textAlign:'center',marginLeft:'auto', marginRight:'auto'}}>Mysic è la soluzione definitiva per gestire la sicurezza aziendale in modo semplice e veloce.</h4>

    <div style={{display:'flex',flexDirection:'row',maxWidth:'90vw',marginTop:'20px',justifyContent:'center'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Account ottimizzati per manutentori e clienti.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'90vw',marginTop:'20px',justifyContent:'center'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Pagine dedicate per: elementi, documenti, interventi, scadenze e mappe.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'90vw',marginTop:'20px',justifyContent:'center'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Filtri rapidi e layout ottimizzati rendono ogni dato accessibile in pochi istanti.</h4>
                </div>
               
             
                <div style={{display:'flex',flexDirection:'row',maxWidth:'90vw',marginTop:'20px',justifyContent:'center'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Scadenziario intuitivo per avere sotto controllo attività e ritardi.</h4>
                </div>
                
                <div style={{display:'flex',flexDirection:'row',maxWidth:'90vw',marginTop:'20px',justifyContent:'center'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Notifiche in tempo reale ed email automatizzate.</h4>
                </div>
                <div style={{display:'flex',flexDirection:'row',maxWidth:'90vw',marginTop:'20px',justifyContent:'center',marginBottom:'30px'}}>
                <CheckIcon/>
                <h4 style={{margin:0,fontFamily:'Poppins',fontWeight:'normal',marginLeft:'5px'}}>Backup sempre aggiornati in formato Excel.</h4>
                </div>
    </div>
    <div style={{minHeight:'25vh',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <h4 style={{fontFamily:'Poppins',color:'#14213d',fontSize:'20px',width:'60%',textAlign:'center',marginLeft:'auto', marginRight:'auto',marginBottom:0,marginTop:'15px' }}>Vai su www.mysic.it da computer per accedere o registrarti al servizio.</h4>
        <h4 style={{fontFamily:'Poppins',color:'#14213d',fontSize:'15px',width:'60%',textAlign:'center',marginLeft:'auto', marginRight:'auto',fontWeight:'200',marginTop:'10px',marginBottom:'20px'}}>Versione mobile in sviluppo, ci scusiamo per il disagio.</h4>
       
    </div>
    <div style={{minHeight:'10vh',width:'100vw',backgroundColor:'black',display:'flex',flexDirection:'column',justifyContent:'center',marginTop:'auto'}}>
    <h4 style={{fontFamily:'Poppins',fontSize:12,width:'fit-content',marginLeft:'auto',marginRight:'auto',color:'white',marginTop:'0px',marginBottom:'0px'}}>Copyright© 2025 Mysic</h4>

    </div>
    <div style={{height:'10vh',width:'100vw',backgroundColor:'black',display:'flex',flexDirection:'column',justifyContent:'center',marginTop:'auto'}}>

    </div>
    </div>
    </div>
    
  )
}

export default PreLoginMobile