import React,{useState,useEffect} from 'react'
import Calendario from '../../Calendario/Calendario'
import { ScadBrev } from '../Components/ScadBrev'
import { getScadenzaRecenti } from '../Engine/engine'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
const SectionCalendario = (props) => {

    const [scadenze,setScadenze] = useState([]) //var per le scadenze
    const [type,setType] = useState(null) //var per tipo
    const [criticita,setCriticita] = useState([]) //
    

    useEffect(()=>{
        if(props.utente!==null&&props.db){
          setType(props.utente.Tipo)
          if(props.utente.Tipo==='Cliente'){
            getCriticitaOp()
            getRitardiOp()
            getScadenzaRecenti(props.db,props.utente.Email,setScadenze);
          }else{
            getCriticitaOp()
            getRitardiOp()
            getScadenzaRecenti(props.db,props.utente.Email,setScadenze);
          }
          
          
        }
      },[props.utente])

      function getCriticitaOp(){
        console.log(props.utente.Email)
        setCriticita([])
        if(props.utente.Tipo==='Cliente'){
          props.db.collection('Manutenzioni').where('Cliente','==',props.utente.Email).where('Esito','==','Negativo').where('SottoAttività','==',true).get().then((man)=>{
            man.forEach(element => {
                setCriticita((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: element.id,
                            ...element.data()
                        }
                    ]
                })
            });
        })
        }else{
        props.db.collection('Manutenzioni').where('Operatore','==',props.utente.Email).where('Esito','==','Negativo').where('SottoAttività','==',true).get().then((man)=>{
            man.forEach(element => {
                setCriticita((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: element.id,
                            ...element.data()
                        }
                    ]
                })
            });
        })
      }
      }
 
      const [ritardi,setRitardi] = useState([]) //ritardi nell evasione delle scadenze

      function getRitardiOp(){
        console.log(props.utente.Email)
        setRitardi([])
        if(props.utente.Tipo==='Cliente'){
          props.db.collection('Manutenzioni').where('Cliente','==',props.utente.Email).where('Stato','==','Pianificata').get().then((man)=>{
            man.forEach(element => {
                setRitardi((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: element.id,
                            ...element.data()
                        }
                    ]
                })
            });
        })
        }else{
          props.db.collection('Manutenzioni').where('Operatore','==',props.utente.Email).where('Stato','==','Pianificata').get().then((man)=>{
            man.forEach(element => {
                setRitardi((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: element.id,
                            ...element.data()
                        }
                    ]
                })
            });
        })
      }
      }

        const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
        
          React.useEffect(() => {
            const handleResize = () => setScreenWidth(window.innerWidth);
            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
          }, []);
        

      const CriticitaComponent = ({ criticita }) => {
        return (
          <div
            style={{
              width: screenWidth<=1440?'45%':"45%",
              padding: screenWidth<=1440?'10px':"20px",
              marginTop:screenWidth<=1440?'1vh':'4vh',
              backgroundColor: "#fff",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
              borderRadius: "8px",
              transform: "translateZ(0)", // Migliora l'effetto di 3D
              position:'absolute'
            }}
          >
            <div style={{display:'flex',alignItems:'center', marginBottom: "10px",}}>
            <h3 style={{margin:0, fontSize: screenWidth<=1440?'15px':"1.5rem", color: criticita.length>0?'red':"#14213d",marginTop:0,fontFamily:'Poppins' }}>
              CRITICITÀ
            </h3>
            <WarningIcon style={{color:criticita.length>0?'red':"#14213d",marginLeft:'5px'}}/>
            </div>
            <div
              style={{
                maxHeight: screenWidth<=1440?'13vh':"20vh",
                overflowY: "auto",
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: screenWidth<=1440?'0px':"10px",
                zIndex:100000
              }}
            >
              {criticita.length > 0 ? (
                criticita.map((crit, i) => (
                  <div
                    key={i}
                    style={{
                      padding: screenWidth<=1440?'5px':"10px",
                      borderBottom: "1px solid #f0f0f0",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

                    <div>

                    <h4 style={{ margin: "0", fontSize: screenWidth<=1440?'0.8rem':"1rem", color: "#192bc2",fontFamily:'Poppins',maxWidth:'20vw',width:'20vw', wordWrap: "break-word" }}>
                      {crit.Titolo}
                    </h4>
                    <p style={{ margin: "0", fontSize: screenWidth<=1440?'0.6rem':"0.9rem", color: "black",fontFamily:'Poppins',fontWeight:'normal',maxWidth:'20vw',width:'20vw', wordWrap: "break-word" }}>
                     {crit.Oggetto}
                    </p>
                    <p style={{ margin: "0", fontSize: screenWidth<=1440?'0.6rem':"0.9rem", color: "#777",maxWidth:'20vw',width:'20vw', wordWrap: "break-word" }}>
                      {crit.Descrizione}
                    </p>
                    </div>
                    <div style={{width:'fit-content',marginLeft:'auto'}}>
                    <IconButton onClick={()=>props.utente.Tipo==='Cliente'?props.history.push(`/scadenze/scadenza/${crit.Id}`):props.history.push(`/scadenzemanutentore/scadenza/${crit.Id}`)} style={{width:'fit-content'}}>
                      <VisibilityIcon/>
                    </IconButton>
                    </div>
                    </div>
                    
                  </div>
                ))
              ) : (
                <h4 style={{ textAlign: "center", color: "#999" }}>Nessuna</h4>
              )}
            </div>
          </div>
        );
      };

      const RitardiComponent = ({ ritardi }) => {

        function returnRitardo() {
          // Ottieni la data di oggi nel formato yyyy-mm-dd
          const today = new Date();
          const todayString = today.toISOString().split("T")[0]; // yyyy-mm-dd
      
          // Filtra i ritardi
          return ritardi.filter((crit) => {
            if (crit.Data) {
              const critDate = new Date(crit.Data); // Converte la data
              const critDateString = critDate.toISOString().split("T")[0]; // yyyy-mm-dd
              return critDateString < todayString; // Controlla se è precedente a oggi
            }
            return false; // Esclude se crit.Data non è presente
          });
        }
      
        const ritardiFiltrati = returnRitardo();

        return (
          <div
            style={{
              width: screenWidth<=1440?'48%':"45%",
              padding: screenWidth<=1440?'10px':"20px",
              marginTop:screenWidth<=1440?'1vh':'4vh',
              backgroundColor: "#fff",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
              borderRadius: "8px",
              transform: "translateZ(0)", // Migliora l'effetto di 3D
              position:'absolute',
              right:0
            }}
          >
            <div style={{display:'flex',alignItems:'center', marginBottom: "10px",}}>
            <h3 style={{margin:0, fontSize: screenWidth<=1440?'15px':"1.5rem", color: ritardiFiltrati.length>0?'red':"#14213d",marginTop:0,fontFamily:'Poppins' }}>
              RITARDI
            </h3>
            <AccessTimeIcon style={{color:ritardiFiltrati.length>0?'red':"#14213d",marginLeft:'5px'}}/>
            </div>
            <div
              style={{
                maxHeight: screenWidth<=1440?'13vh':"20vh",
                overflowY: "auto",
                border: "1px solid #ddd",
                borderRadius: "5px",
                padding: screenWidth<=1440?'0px':"10px",
              }}
            >
              {ritardiFiltrati.length > 0 ? (
                ritardiFiltrati.map((crit, i) => (
                  <div
                    key={i}
                    style={{
                      padding: screenWidth<=1440?'5px':"10px",
                      borderBottom: "1px solid #f0f0f0",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>

                    <div>

                    <h4 style={{ margin: "0", fontSize: screenWidth<=1440?'0.8rem':"1rem", color: "#192bc2",fontFamily:'Poppins',maxWidth:'20vw',width:'20vw', wordWrap: "break-word" }}>
                      {crit.Titolo}
                    </h4>
                    <p style={{ margin: "0", fontSize: screenWidth<=1440?'0.6rem':"0.9rem", color: "black",fontFamily:'Poppins',fontWeight:'normal',maxWidth:'20vw',width:'20vw', wordWrap: "break-word" }}>
                     {crit.Oggetto}
                    </p>
                    <p style={{ margin: "0", fontSize: screenWidth<=1440?'0.6rem':"0.9rem", color: "#777",maxWidth:'20vw',width:'20vw', wordWrap: "break-word" }}>
                      {crit.Descrizione}
                    </p>
                    </div>
                    <div style={{width:'fit-content',marginLeft:'auto'}}>
                    <IconButton onClick={()=>props.utente.Tipo==='Cliente'?props.history.push(`/scadenze/scadenza/${crit.Id}`):props.history.push(`/scadenzemanutentore/scadenza/${crit.Id}`)} style={{width:'fit-content'}}>
                      <VisibilityIcon/>
                    </IconButton>
                    </div>
                    </div>
                    
                  </div>
                ))
              ) : (
                <h4 style={{ textAlign: "center", color: "#999" }}>Nessuno</h4>
              )}
            </div>
          </div>
        );
      };

      
      
    

  return (
    <div>
        <div style={{display:'flex'}}>

         <div style={{marginTop:'1.5rem',zIndex:1000}}>
      <Calendario utente={props.utente} type={props.type}/>
      </div>
      <div style={{marginTop:'1rem'}}>
      <div className="container-dash-c" style={{width:window.innerWidth>1440?'25rem':'18rem',marginRight:'2rem',paddingLeft:'1vw'}}>
      <h4 className="title-section" style={{marginTop:'0rem',padding:'5px'}}>Scadenze a breve ({scadenze.length})</h4>
      <div  style={{height:'25rem',overflowY:'auto',overflowX:'hidden',padding:'5px',zIndex:'10000'}}>
        {scadenze.length>0?scadenze.map((not, index)=>(
          <ScadBrev not={not} index={index}/>
        )): <div
        style={{
          
          borderRadius: 10,
          marginLeft:10
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <h4
            style={{
              fontSize: "1rem",
              color: "black",
              fontWeight: "normal",
              fontFamily: "Poppins",
              marginBottom: 0,
              marginTop: "0.5vw",
              marginLeft: "0.5vw",

              width: "fit-content",
            }}
          >
            Nessuna scadenza a breve presente
          </h4>
        </div>
      </div>}
      </div>

    
      </div>
      </div>
      </div>
      <div style={{zIndex:1000,position:'relative',display:'flex',width:screenWidth<=1440?'65vw':'60vw',backgroundColor:'blue',justifyContent:'space-between'}}>
      <CriticitaComponent criticita={criticita}/>
      <RitardiComponent ritardi={ritardi}/>

      </div>
    </div>
  )
}

export default SectionCalendario