//popup aggiungi categoria
import React, { useState, useEffect } from "react";
import "../../Popups/Popup.css";
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente } from "../../EngineClienti/engineClienti";
import { getClientiSediCategorie,getExtraSottocategorie } from "../../EngineOffritore/engineOffritore";
import { getSedeName } from "../../EngineClienti/engineClienti";
import NavBar2 from "../../NavBar/NavBar2";
import { useCurrentCli } from "../../../CurrentCliContext";

const AggiungiElementoOff = ({history}) => {

    const { currentCli,updateCurrentCli } = useCurrentCli();  // Ottieni il cliente selezionato dal Context
  

    const auth = firebase.auth();
    const db = firebase.firestore();

    const [userM,setUserM] = useState(null) //dati manutentore

    const [categorie, setCategorie] = useState([]); //lista categorie
    const [sedi,setSedi] = useState([])
    const [clienti,setClienti] = useState([])
    const [sottocategorieExtra,setSottocategorieExtra] = useState([]) //lista sottocategorie aggiunte extra

    const [selectedSede,setSelectedSede] = useState('default')
    const [selectedCliente,setSelectedCliente] = useState('default')

    const [selectedCategoria,setSelectedCategoria] = useState('default')
    const [selectedSottocategoria,setSelectedSottocategoria] = useState('default')
    const [extraDet,setExtraDet] = useState(false)
    const [marcaE, setMarca] = useState(""); //var per marca
    const [inst, setInst] = useState(""); //var per data installazione
    const [number,setNumber] = useState(1)

    const [added,setAdded] = useState([]) //elementi aggiunti

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUserM)
          }
        })
      },[auth])
    
      useEffect(()=>{
        if(userM!==null){
         getClientiSediCategorie(db,userM.Email,setClienti,setSedi,setCategorie)      
      }
      },[userM])





      useEffect(()=>{
        setSelectedSede('default')
        if(selectedCliente!=='default'){
          getExtraSottocategorie(db,selectedCliente,setSottocategorieExtra)
          sessionStorage.setItem('cliente_adding', selectedCliente);
        }

       },[selectedCliente])

      useEffect(()=>{
        if(selectedCategoria==='Antincendio'){
            setSelectedSottocategoria("default")
        }else if(selectedCategoria==='Elettrico'){
            setSelectedSottocategoria("default")
        }else{
            setSelectedSottocategoria("default")
        }
      },[selectedCategoria])

      useEffect(()=>{
       setSelectedCategoria('default')
      },[selectedSede])

      useEffect(() => {
        console.log('Eseguo useEffect per recuperare added dalla cache.');
    
        const cachedAdded = sessionStorage.getItem(`added_${selectedCliente}`);
        
        if (cachedAdded) {
            try {
                const parsedAdded = JSON.parse(cachedAdded);
                if (Array.isArray(parsedAdded)) {
                    console.log("Dati trovati in cache:", parsedAdded);
                    setAdded(parsedAdded);
                } else {
                    console.error("Cache corrotta, non è un array:", parsedAdded);
                    sessionStorage.removeItem(`added_${selectedCliente}`); // Rimuove cache corrotta
                }
            } catch (error) {
                console.error("Errore nel parsing della cache:", error);
                sessionStorage.removeItem(`added_${selectedCliente}`);
            }
        } else {
            console.log("Nessun dato in cache per", `added_${selectedCliente}`);
        }
    }, [selectedCliente]);

    useEffect(()=>{
      const cachedCliente = sessionStorage.getItem('cliente_adding');
      if (cachedCliente) {
        setSelectedCliente(cachedCliente);
      }
    },[])


      function addElement() {
        let startingIndex = 0; // Indice per gli elementi da inserire
        let marca = ''; // Marca per gli elementi da inserire
        let date = ''; // Data installazione per gli elementi da inserire
        let array = []; // Array di nuovi elementi
      
        const batch = db.batch(); // Batch per operazioni multiple su Firestore
        const sedename = getSedeName(selectedSede, sedi);
      
        // Recupera i dati dell'utente (cliente) per aggiornare le quantità
        db.collection('Users').doc(selectedCliente).get().then((usx) => {
          const user = usx.data();
          const tot = parseInt(user.Current_Ele, 10) + parseInt(number, 10);
          if (tot <= user.Max_Ele) {
            if (parseInt(number, 10) >= 1) {
              db.collection('Users').doc(user.Email)
                .collection('Sedi').doc(selectedSede)
                .collection('Categorie').doc(selectedCategoria)
                .collection('Sottocategorie').doc(selectedSottocategoria)
                .collection('Elementi').get().then((elements) => {
                  if (elements.docs.length > 0) {
                    startingIndex = elements.docs.length;
                  }
      
                  if (extraDet === true) {
                    marca = marcaE;
                    date = inst;
                  }
      
                  // Aggiorna i contatori sul DB
                  db.collection('Users').doc(user.Email).update({
                    Current_Ele: firebase.firestore.FieldValue.increment(parseInt(number, 10))
                  });
                  db.collection('Users').doc(userM.Email).update({
                    Current_Ele: firebase.firestore.FieldValue.increment(parseInt(number, 10))
                  });
                  db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede).update({
                    Ele: firebase.firestore.FieldValue.increment(parseInt(number, 10))
                  });
                  db.collection('Users').doc(userM.Email).collection('Clienti').doc(selectedCliente).update({
                    Ele: firebase.firestore.FieldValue.increment(parseInt(number, 10))
                  });
                  db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede)
                    .collection('Categorie').doc(selectedCategoria).update({
                      Ele: firebase.firestore.FieldValue.increment(parseInt(number, 10))
                    });
      
                  // Gestione sottocategoria: se è la prima volta, crea il documento, altrimenti aggiorna
                  if (startingIndex === 0) {
                    db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede)
                      .collection('Categorie').doc(selectedCategoria)
                      .collection('Sottocategorie').doc(selectedSottocategoria)
                      .set({
                        Nome: selectedSottocategoria,
                        Sede: selectedSede,
                        Valore: number
                      });
                  } else {
                    db.collection('Users').doc(user.Email).collection('Sedi').doc(selectedSede)
                      .collection('Categorie').doc(selectedCategoria)
                      .collection('Sottocategorie').doc(selectedSottocategoria)
                      .update({
                        Valore: firebase.firestore.FieldValue.increment(parseInt(number, 10))
                      });
                  }
      
                  // Ciclo per creare i nuovi elementi
                  for (var q = 0; q < number; q++) {
                    const docRef = db.collection("Elementi").doc();

                    const nome_ele = 
                      selectedSottocategoria[0].toUpperCase() +
                      selectedSottocategoria[1].toUpperCase() +
                      selectedSottocategoria[2].toUpperCase() +
                      startingIndex;
                    array.push({
                      Id: docRef.id,
                      ID: nome_ele,
                      Descrizione: "",
                      Sede: selectedSede,
                      SedeName: sedename,
                      Code: "",
                      Posizione: "",
                      note: "",
                      RFID: "",
                      BARCODE: "",
                      Categoria: selectedCategoria,
                      Marca: marca,
                      Macrocategoria: selectedSottocategoria,
                      Data_Inst: date,
                      Data_Scad: "",
                      Nome: nome_ele,
                      Valore: 0,
                      Shared: userM.Email,
                      Cliente: user.Email
                    });
                   
                    startingIndex++;
                  }
      
                 
                  var addedforcache = []

                  // Prepara il batch per aggiungere gli elementi al DB
                  array.forEach((doc) => {
                    console.log(doc)
                    batch.set(db.collection('Elementi').doc(doc.Id), doc);

                    const index = array.findIndex(e=> e.ID === doc.ID && e.Categoria === doc.Categoria && doc.Macrocategoria === e.Macrocategoria)

                   // if(index!==-1){
                    //  array[index].Id = docRef.id
                   // }
//
                    console.log(doc, index)

                    setAdded((prev) => [
                      ...prev,
                      {
                        Nome: doc.Nome,
                        Categoria: selectedCategoria,
                        Sottocategoria: selectedSottocategoria,
                        Marca: doc.Marca,
                        Inst: doc.Data_Inst,
                        Id: doc.Id,
                        Sede: selectedSede,
                        SedeName: sedename
                      }
                    ]);

                    addedforcache.push({
                      Nome: doc.Nome,
                        Categoria: selectedCategoria,
                        Sottocategoria: selectedSottocategoria,
                        Marca: doc.Marca,
                        Inst: doc.Data_Inst,
                        Id: doc.Id,
                        Sede: selectedSede,
                        SedeName: sedename
                    })

                    
      
                    // Aggiungi la ref all'interno delle collezioni relative
                    db.collection("Users")
                      .doc(user.Email)
                      .collection("Sedi")
                      .doc(selectedSede)
                      .collection("Categorie")
                      .doc(selectedCategoria)
                      .collection("Sottocategorie")
                      .doc(selectedSottocategoria)
                      .collection("Elementi")
                      .doc(doc.Id)
                      .set({ ref: doc.Id });
      
                    db.collection('Users').doc(userM.Email)
                      .collection('Clienti').doc(selectedCliente)
                      .collection('Elementi')
                      .doc(doc.Id)
                      .set({ ref: doc.Id });
      
                    // Aggiorna anche la cache se necessario (puoi opzionalmente farlo qui)
                  });

                  let cacheKey = `elementiCliente_${selectedCliente}`;
                  const cachedData = sessionStorage.getItem(cacheKey);
                  let parsedData;
                  
                  // Se ci sono dati già salvati in cache, li recuperiamo
                  if (cachedData) {
                    parsedData = JSON.parse(cachedData);
                  
                    // Estrai gli elementi esistenti
                    const existingElementi = parsedData.elementi || [];
                    const existingCategorie = parsedData.categorie || [];
                    const existingSottoCategorie = parsedData.sottocategorie || [];
                  
                    // Unisci gli elementi nuovi con quelli già presenti nella cache
                    const updatedElementi = [...existingElementi, ...array];
                  
                    // Aggiungi direttamente selectedCategoria e selectedSottocategoria
                    existingCategorie.push(selectedCategoria); 
                    existingSottoCategorie.push(selectedSottocategoria);
                 
                  
                    // Aggiorna la cache con i nuovi dati
                    const updatedCacheData = {
                      ...parsedData,
                      elementi: updatedElementi,
                      categorie: existingCategorie,
                      sottocategorie: existingSottoCategorie
                    };
                  
                    // Salva l'oggetto aggiornato nella cache
                    sessionStorage.setItem(cacheKey, JSON.stringify(updatedCacheData));
                  }

                  // Aggiorna la cache per le sottocategorie
                  let sottocategorieCache = [];
                  const cachedSott = sessionStorage.getItem('sottocategorie');
                  if (cachedSott) {
                    sottocategorieCache = JSON.parse(cachedSott);
                  }
                  if (sottocategorieCache.findIndex(e => e.Nome === selectedSottocategoria) === -1) {
                    sottocategorieCache.push({
                      Nome: selectedSottocategoria,
                      Sede: selectedSede,
                    });
                  }
                  sessionStorage.setItem('sottocategorie', JSON.stringify(sottocategorieCache));

                  //aggiorna cache aggiunti di recente
                  const cachedAdded = sessionStorage.getItem(`added_${selectedCliente}`);

                  console.log(addedforcache)

if (cachedAdded) {
    const parsedAdded = JSON.parse(cachedAdded);
    console.log("Unisco gli elementi alla cache:", parsedAdded, added);

    const updatedAdded = [...parsedAdded, ...addedforcache];
    sessionStorage.setItem(`added_${selectedCliente}`, JSON.stringify(updatedAdded));
    setAdded(updatedAdded);
} else {
    console.log("Salvo nuovi dati in cache per la prima volta:", added);
    sessionStorage.setItem(`added_${selectedCliente}`, JSON.stringify(addedforcache));
}

      
                  batch.commit().then(() => {
                    alert("Caricamento completato");
                  }).catch(error => {
                    console.error("Errore nel commit del batch:", error);
                  });
                })
                .catch((error) => {
                  console.error("Errore nel caricamento degli elementi:", error);
                });
            } else {
              alert('Non puoi aggiungere un numero negativo di elementi');
            }
          } else {
            alert('Non puoi aggiungere ulteriori elementi, fai un upgrade del tuo account Mysic per aggiungere nuovi elementi.');
          }
        });
      }
      

        function returnSottoExtra(){
          var arr = sottocategorieExtra.filter((e)=>e.Categoria===selectedCategoria)
          return arr
        }


  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
        <NavBar2/>
         <div
        style={{height:'100vh',width:'85vw',marginLeft:'15vw',overflow:'hidden'}}
      >
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem'}}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>AGGIUNGI ELEMENTO</h4>
          <h4
          className="sub-text" style={{width:'50vw'}}
        >
          Aggiungi gli elementi ai tuoi clienti
        </h4>
          </div>

        </div>
        <div style={{display:'flex',overflow:'hidden'}}>
                   {/*SELEZIONE CLIENTE */}

      <div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'22rem'}} >
      <h4 className="sub-text-b" style={{}}>Seleziona il cliente a cui aggiungere gli elementi:</h4>
        <select className="input-style"
        defaultValue={"default"}
        value={selectedCliente} onChange={(e) => {
            setSelectedCliente(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                {clienti && clienti.length > 0 && clienti.map((cliente,n)=>{
                                  return <option key={n} value={cliente.Email}>{cliente.Nome_Azienda}</option>
                              })}
        </select>
                           {/*SELEZIONE SEDE */}
        {selectedCliente!=='default'?<div style={{marginTop:'1rem'}}>                      
        <h4  className="sub-text-b">Seleziona sede:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedSede} onChange={(e) => {
            setSelectedSede(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                    if(sede.Cliente===selectedCliente){

                                  return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>
                                    }else{
                                      return null
                                    }
                              })}
        </select></div>:null}
               {/*SELEZIONE CATEGORIA */}

        {selectedSede!=='default'?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona categoria:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedCategoria} onChange={(e) => {
            setSelectedCategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                {categorie && categorie.length > 0 && categorie.map((cat,n)=>{if(cat.Sede===selectedSede&&cat.Cliente===selectedCliente){
                                  return <option key={n} value={cat.Id}>{cat.Nome}</option>
                }else{
                  return null
                }
                              })}
        </select></div>:null}
               {/*SELEZIONE SOTTOCATEGORIA */}

        {selectedCategoria!==''&&selectedCategoria!=='default'?<div style={{marginTop:'1rem'}}><h4 className="sub-text-b">Seleziona sottocategoria:</h4>
        {/*se la categoria è antincendio*/}
        {selectedCategoria==='Antincendio'?<select className="input-style" defaultValue={"default"}
        value={selectedSottocategoria} onChange={(e) => {
            setSelectedSottocategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sottocategoria...
                </option>
                {returnSottoExtra().length>0?returnSottoExtra().map((sott,i)=>(
                   <option value={sott.Id} key={i}>
                  {sott.Nome}
                 </option>
                )):null}
                <option value="Estintore polvere">
                          Estintore a polvere
                        </option>
                        <option value="Estintore CO2">Estintore CO2</option>
                        <option value="Estintore schiuma">
                          Estintore schiuma
                        </option>
                        <option value="Estintore acqua">Estintore acqua</option>
                        <option value="Porta-Portone REI">
                          Porta/Portone REI
                        </option>
                        <option value="Dispositivo antipanico">
                          Dispositivo antipanico
                        </option>
                        <option value="Gruppo di pressurizzazione">
                          Gruppo di pressurizzazione
                        </option>
                        <option value="Attacco Mandata VVF">
                          Attacco Mandata VVF
                        </option>
                        <option value="Rivelatore Antincendio">
                          Rivelatore antincendio
                        </option>
                        <option value="Aspiratore">Aspiratori</option>
                        <option value="Idrante a muro">Idrante a muro</option>
                        <option value="Idrante a colonna">
                          Idrante a colonna
                        </option>
                        <option value="Segnalazione ottico-acustica">
                          Segnalazione ottico-acustica
                        </option>
                        <option value="Pulsante">Pulsante</option>
        </select>:null}
         {/*se la categoria è elettrico*/}
        {selectedCategoria==='Elettrico'?<select className="input-style" defaultValue={"default"}
        value={selectedSottocategoria} onChange={(e) => {
            setSelectedSottocategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sottocategoria...
                </option>
                {returnSottoExtra().length>0?returnSottoExtra().map((sott,i)=>(
                   <option value={sott.Id} key={i}>
                  {sott.Nome}
                 </option>
                )):null}
                <option value="Lampada emergenza">
                              Lampada d'emergenza
                            </option>
                            <option value="Quadro elettrico">
                              Quadro elettrico
                            </option>
                            <option value="Interruttore differenziale">
                              Interruttore differenziale
                            </option>
        </select>:null}
        {selectedCategoria!=='Antincendio'&&selectedCategoria!=='Elettrico'?
                   <select className="input-style" defaultValue={"default"}
                   value={selectedSottocategoria} onChange={(e) => {
                       setSelectedSottocategoria(e.target.value);
                     }}>
                       <option value={"default"} disabled>
                             Seleziona sottocategoria...
                           </option>
                       {returnSottoExtra().length>0?returnSottoExtra().map((sott,i)=>(
                      
                   <option value={sott.Id} key={i}>
                  {sott.Nome}
                 </option>)):null}
                 </select>:null}
       
       </div>:null}
       {/*SELEZIONE NUMERO */}
       {selectedSottocategoria!=='default'?<div style={{marginTop:'1rem'}}>
               <h4  className="sub-text-b">Quanti elementi vuoi aggiungere?</h4>

        <input className="input-style2" value={number} onChange={(e)=>setNumber(e.target.value)} style={{width:'2rem',marginTop:'5px'}} type='number'/>
       </div>:null}
       {number!==0&&selectedSottocategoria!=='default'&&selectedCategoria!==''&&selectedCategoria!=='default'&&
       selectedSede!==''?
       <button onClick={()=>addElement()} className="main-button" style={{marginTop:'2rem'}}>Aggiungi</button>:null}

       </div>
              {/*INFORMAZIONI AGGIUNTIVE */}

       <div style={{marginTop:'1rem'}}>
       {selectedSottocategoria!=='default'?<div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
          <input type='checkbox' onChange={()=>setExtraDet(!extraDet)} checked={extraDet}></input>
          <h4 className="medium-text">Informazioni aggiuntive</h4>
        </div>:null}
       {extraDet===true?<div>
        <div
                              style={{
                                marginTop:'1rem',
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "fit-content" }}>
                                <h4
                                  hidden={extraDet === false ? true : false}
                                  className="sub-text-b"
                                 
                                >
                                  Marca:{" "}
                                </h4>

                                {extraDet === true ? (
                                  <input
                                    type="text"
                                    onChange={(e) => setMarca(e.target.value)}
                                    className="input-style"
                                    placeholder="Inserisci marca..."
                                  />
                                ) : null}
                              </div>
                              <div style={{marginTop:'1rem'}}>
                                <h4
                                  hidden={extraDet === false ? true : false}
                                  className="sub-text-b"
                                >
                                  Data installazione:{" "}
                                </h4>
                                {extraDet === true ? (
                                  <div
                                    style={{
                                     
                                      width: "fit-content",
                                    }}
                                  >
                                    <input
                                      type="date"
                                      style={{marginTop:'2px'}}
                                      onChange={(e) => setInst(e.target.value)}
                                      className="input-style"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
       </div>:null}
       </div>
              {/*AGGIUNTI DI RECENTE */}
        <div className="box-shadow2" style={{height:'50rem',width:'30rem',marginLeft:'auto',marginRight:'2rem',borderRadius:'10px',overflowY:'auto'}}>
         <h4 className="title-medium" style={{marginTop:'1rem',marginLeft:'1rem'}}>Aggiunti di recente</h4>
         {added.length>0?added.slice().reverse().map((add,n)=>(
            <div key={n}  style={{marginTop:'1rem',marginLeft:'1rem',borderBottom:'1px solid lightgrey',paddingBottom:'1rem'}}>
            <h4 className="normal-text" onClick={()=>history.push(`/elementi/elemento/${add.Id}`)} style={{color:'#192bc2',cursor:'pointer'}}>• {add.Nome}</h4>
            <h4 className="sub-text">{add.Categoria} - {add.Sottocategoria}</h4>
            <h4 className="sub-text">Sede: {add.SedeName}</h4>

            {add.Marca!==''?<h4 className="sub-text">Marca: {add.Marca}</h4>:null}
            {add.Inst!==''?<h4 className="sub-text">Installazione: {add.Inst}</h4>:null}
            </div>
         )):<h4 className="medium-text" style={{marginTop:'1rem',marginLeft:'1rem'}}>Nessun elemento aggiunto di recente...</h4>}                           
        </div>

      </div>
      </div></div>
  )
}

export default AggiungiElementoOff