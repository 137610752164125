import React, { useEffect,useState } from 'react'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton } from '@mui/material';
import firebase from "firebase";
import CancelIcon from "@material-ui/icons/Cancel";
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import DeleteIcon from '@mui/icons-material/Delete';
import { checkDeleteS } from '../EngineClienti/engineClienti';
const Notifiche = (props) => {
    const db = firebase.firestore(); //database firebase
    const auth = firebase.auth(); //autentication firebase

    const [notifiche,setNotifiche] = useState([])
    const [toSee,setToSee] = useState(null)

    const [trigger,setTrigger] = useState(false)
 
    useEffect(()=>{
        if(props.user!==null){
            console.log(props.user)
            getNotifiche()
        }

    },[auth])

    function getNotifiche(){
        var control = false //render bollino rosso
        var i = 1
        setNotifiche([])
        db.collection('Users').doc(props.user.Email).collection('Notifiche').orderBy('Data','desc').get().then((notific)=>{
            notific.forEach((not)=>{
                if(i===notific.docs.length){
                    if(control===false){
                        setToSee(true)
                    }
                }
                if(not.data().Visualizzato){
                    control = true
                }
                setNotifiche((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: not.id,
                            ...not.data()

                        }
                    ]
                })
                i++

            })
        })
    }

    async function deleteAll() {
        if (window.confirm("Sei sicuro di voler eliminare tutte le notifiche?")) {
            try {
                // Recupera tutte le notifiche dell'utente dal database
                const notificheSnapshot = await db.collection('Users').doc(props.user.Email).collection('Notifiche').get();
                
                // Cicla su tutte le notifiche e cancellale
                const batch = db.batch();  // Usare un batch per eliminazioni di massa
                notificheSnapshot.forEach((doc) => {
                    batch.delete(doc.ref); // Elimina ogni documento
                });
    
                // Esegui l'eliminazione batch
                await batch.commit();
    
                // Aggiorna lo stato delle notifiche per rimuovere tutto dal layout
                setNotifiche([]);
                alert("Tutte le notifiche sono state eliminate con successo.");
            } catch (error) {
                alert("Errore nell'eliminazione delle notifiche: " + error.message);
            }
        }
    }

    const VisualizeNotifiche = () =>{

        function deleteNotifica(notID,index){
            db.collection('Users').doc(props.user.Email).collection('Notifiche').doc(notID).delete().then(()=>{
                var arr = [...notifiche]
                arr.splice(index, 1)
                setNotifiche(arr)
            })
        }

        return (
            <div className="pop-opac" style={{zIndex:10000}}>
            <div className="popup"
                style={{
                backgroundColor: "white",
                width: "60vw",
                left: "20vw",
                height: "70vh",
                top: "10vh",
                zIndex: 2000,
                }}
            >
 <div style={{ textAlign: "left", width: "fit-content" }}>
          <button
            className="cancelIconButton"
            onClick={() => setTrigger(false)}
          >
            <CancelIcon
              style={{ width: "15px", height: "15px" }}
              className="cancelIcon"
            />
          </button>
        </div>
        <div style={{width:'100%',display:'flex',flexDirection:'row'}}>
        <h4
         className='page-title' style={{margin:0, marginLeft:'2vw'}}
        >
          NOTIFICHE
        </h4>
        <button onClick={()=>deleteAll()} className='text-buttons2' style={{cursor:'pointer',marginLeft:'auto',marginRight:'1vw',color:'red',fontWeight:'bold',borderBottomColor:'red',borderRadius:0}}>Elimina tutto</button>
        </div>
        <div style={{marginLeft:'2vw',height:'58vh',width:'56vw',overflowY:'auto'}}>
        {notifiche.length>0?notifiche.map((not,n)=>(
            <div key={n} style={{borderBottom:'1px solid lightgrey',padding:5}}>
                <div style={{display:'flex',alignItems:'center'}}>
                    <HomeWorkIcon style={{color:'#192bc2'}}/>
             <h4 className='title-medium' style={{color:'#192bc2'}}>{not.Nome_Azienda}</h4> 
             <h4 className='sub-text' style={{marginLeft:'1vw'}}>({not.Email})</h4> 
             </div>
             <h4 className='normal-text' style={{fontSize:'1rem',}}>Oggetto: <b>{not.Oggetto}</b></h4> 

             <h4 className='normal-text' style={{fontSize:'1rem'}}>{not.Nome_Azienda} {not.Messaggio}</h4> 
             <h4 className='sub-text' style={{fontSize:'0.7rem'}}>{not.Data}</h4> 
             {checkDeleteS(props.user?props.user.Permissions:null)===true?<div style={{width:'fit-content',marginLeft:'auto'}}>
             <IconButton onClick={()=>deleteNotifica(not.Id,n)} style={{width:'fit-content',marginLeft:'auto'}}>
            <DeleteIcon style={{color:'#FF0060'}}/>
            </IconButton>
             </div>:null}
          
            </div>
        )):<h4 className='normal-cool'>Nessuna notifica presente.</h4>}
        </div>
             </div>
            </div>
        )
    }

    function triggerPopup(){
        setTrigger(true)
        notifiche.forEach((not)=>{
            db.collection('Users').doc(props.user.Email).collection('Notifiche').doc(not.Id).update({
                Visualizzato: true
            })
            setToSee(false)
            
        })
    }

      const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
            
              React.useEffect(() => {
                const handleResize = () => setScreenWidth(window.innerWidth);
                window.addEventListener("resize", handleResize);
                return () => window.removeEventListener("resize", handleResize);
              }, []);



  return (
    <div style={{position:'absolute',right:'2rem',top:'1.5rem',zIndex:'10000000',padding:3,backgroundColor:'#14213d',borderRadius:50,height:'5vh'}}>
        <div style={{position:'relative'}}>
            {toSee===true?<div style={{width:'10px',height:'10px',borderRadius:50,position:'absolute',top:5,right:5,backgroundColor:'#FF0060'}}></div>:null}

        <IconButton onClick={()=>triggerPopup()} style={{width:'fit-content'}}>
            <div style={{position:'relative'}}>
            <h4 className='medium-text' style={{position:'absolute',bottom:screenWidth<=1440?'-7px':'-10px',color:'white',left:screenWidth<=1440?'-2px':'-1px',fontSize:'0.6vw'}}>{notifiche.length}</h4>
            <NotificationsIcon style={{color:'white',fontSize:screenWidth<=1440?'20px':null}}/>
            </div>
           
        </IconButton>
        </div>
        {trigger===true?<VisualizeNotifiche trigger={trigger} setTriggerz={setTrigger}/>:null}
    </div>
  )
}

export default Notifiche