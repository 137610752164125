//barra superiore
import React, {useState} from "react";
import { withRouter } from "react-router";
import '../Style/prelogin.css'
import logo from '../../images/mysic-lungo2.png'
import { useHistory } from "react-router-dom";

const UpperBar = ({ history }) => {
  const navigate = useHistory();

  function handleClick() {
    navigate.push("/login");
  }
  function handleClick2() {
    navigate.push("/register");

  }

  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
    <div
  
     style={{backgroundColor: 'rgba(255,255,255,1)', width:'80vw', height:'6vh',display:'flex',left:'10vw',right:'10vw',position:'absolute',zIndex:100,top:'1vh',borderRadius:'50px'
      ,flexDirection:'row',alignItems:'center'}}
    >
        <img src={logo} alt='logo_mysic' style={{marginLeft:'5vw',height:'5vh'}}></img>
       <h4 onClick={()=>handleClick()} style={{margin:0,marginLeft:'auto',marginRight:'5vw',fontFamily:'Poppins',fontSize:screenWidth <= 1440 ?'12px':'15px',color:'#14213d',fontWeight:'400',borderBottom:'1px solid #14213d',cursor:'pointer'}}>Accedi</h4>
    </div>
  );
};

export default UpperBar;