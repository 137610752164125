//sezione account
import React, { useState, useEffect } from "react";
import firebase from "firebase";
import EditIcon from '@mui/icons-material/Edit';
import { IconButton } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BuildIcon from '@mui/icons-material/Build';
import { getIcon,checkDeleteS,checkWriteS } from "../../EngineClienti/engineClienti";

const Dettagli = (props) => {

    const db = firebase.firestore(); //database firebase

    const [categoria,setCategoria] = useState(props.categoria)
    
    const [editN,setEditN] = useState(false) //trigger edit nome

    const [newNome,setNewNome] = useState('') //var nuovo nome

     const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
                      
                        React.useEffect(() => {
                          const handleResize = () => setScreenWidth(window.innerWidth);
                          window.addEventListener("resize", handleResize);
                          return () => window.removeEventListener("resize", handleResize);
                        }, []);
          

    function salvaNome(){

      var arr = [...categoria.Sedi]

      console.log(arr)
      arr.forEach((sede)=>{
        console.log(sede.Id)
        db.collection('Users').doc(props.user.Email).collection('Sedi').doc(sede.Id).collection('Categorie').doc(categoria.Id).update({
          Nome: newNome
        })
      })

        var e = categoria
        e.Nome=newNome
        setCategoria(e)
        props.setCategoria(e)

    }

    function returnElementi(){
      var arr = categoria
      var n = 0
      var i = 0
      arr.Sedi.forEach((sede)=>{
        n = n + sede.Ele
        i++       
      })
      if(i===(arr.Sedi.length)){
        return n
      }
    }
    function returnDocumenti(){
      var arr = categoria
      var n = 0
      var i = 0
      arr.Sedi.forEach((sede)=>{
        n = n + sede.Doc
        i++       
      })
      if(i===(arr.Sedi.length)){
        return n
      }
    }

    return <div style={{paddingLeft:'1rem',height:'80vh',overflowY:'auto',overflowX:'hidden',width:screenWidth<=1440?'40vw':'71vw'}}>
      <div>
      {/*informazioni di contatto*/}
      <div>
        <div style={{display:'flex',alignItems:'center',marginTop:'1rem'}}>
        <BuildIcon style={{color:'black'}}/>

      <h4 className="header-normal" style={{marginLeft:'5px'}}>Informazioni categoria</h4>
      </div>
      {/*nome*/}
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
        {editN===false?<h4 className="normal-text">{categoria.Nome}</h4>:<input type='text' className='input-style' placeholder="Inserisci..." onChange={(e)=>setNewNome(e.target.value)}></input>}
    <h4 className="sub-text">NOME CATEGORIA</h4>
        </div>
        {categoria.Nome!=='Antincendio'&&categoria.Nome!=='Elettrico'?<div style={{marginLeft:'auto',width:'fit-content'}}>
          {editN===false?checkWriteS(props.user?props.user.Permissions:null)===true?<IconButton style={{width:'fit-content'}} onClick={()=>setEditN(true)}>
            <EditIcon></EditIcon>
          </IconButton>:null: <div style={{display:'flex',alignItems:'center',marginLeft:5}}>
        <IconButton style={{width:'fit-content',backgroundColor:'lightgreen'}}>
        <CheckIcon onClick={()=>{setEditN(false);newNome!==''?salvaNome():alert('Inserisci il nuovo nome')}} style={{color:'green'}}/>
        </IconButton>
        <IconButton onClick={()=>{setEditN(false);setNewNome('')}} style={{marginLeft:5,backgroundColor:'lightpink',width:'fit-content'}}>
        <CloseIcon style={{color:'red'}}/>
        </IconButton>
        </div>}
        </div>:null}
      </div>
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
       <h4 className="normal-text">{categoria.Sedi.length}</h4>
    <h4 className="sub-text">N. SEDI</h4>
        </div>
      </div>
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
       <h4 className="normal-text">{returnElementi()}</h4>
    <h4 className="sub-text">N. ELEMENTI</h4>
        </div>
      </div>
      <div className="flex-row" style={{width:'45vw'}}>
        <div style={{marginTop:'1rem',height:'4rem',width:'fit-content',maxWidth:'40vw'}}>
       <h4 className="normal-text">{returnDocumenti()}</h4>
    <h4 className="sub-text">N. DOCUMENTI</h4>
        </div>
      </div>
      </div>
      </div>
      <div style={{alignItems:'center',marginTop:'5vh',marginLeft:'auto',width:'fit-content'}}>
        </div>
    </div>
}

export default Dettagli;