import React, {useState,useEffect} from 'react'
import NavBar2 from '../NavBar/NavBar2'
import {  Icon, IconButton, Tooltip } from '@mui/material';
import LoopIcon from '@material-ui/icons/Loop';
import { getClienti,getRichieste} from '../EngineOffritore/engineOffritore';
import {getUtente,checkWriteS} from '../EngineClienti/engineClienti'
import firebase from "firebase";
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { sendEmail } from '../EmailService/EmailService';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
const Clienti = () => {

    const db = firebase.firestore()
    const auth = firebase.auth()

    const [user,setUser] = useState(null)
    const [clienti,setClienti] = useState([]) //lista clienti
    const [richieste,setRichieste] = useState([]) //lista richieste

    const [findInput,setFindInput] = useState('')
    const [triggerReq,setTriggerReq] = useState(false) //trigger popup richieste

                //sorting
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const [defaultCli,setDefaultCli] = useState(null)
    

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

      useEffect(()=>{
        if(user!==null){
            getClienti(db,user.Email,setClienti)
            getRichieste(db,user.Email,setRichieste)
            setDefaultCli(user.DefaultCli)
        }
    },[user])


    const Buttons = () =>{

        return(
          <div style={{display:'flex',alignItems:'center',gap:'1.5rem'}}>
            <div>
            <h4 onClick={()=>setTriggerReq(true)}  className='text-buttons' >
                    VISUALIZZA RICHIESTE ({richieste.length})
                  </h4>
            </div>
          
          </div>
        )
      }

      function reGet (){
       getClienti(db,user.Email,setClienti)
      
      }
      function renderCliente(cliente){

        console.log((findInput===''||cliente.Nome_Azienda.toLowerCase().includes(findInput.toLowerCase())||
        cliente.Email.toLowerCase().includes(findInput.toLowerCase())||cliente.Numero.toLowerCase().includes(findInput.toLowerCase())||
        cliente.Indirizzo.toLowerCase().includes(findInput.toLowerCase())||cliente.Comune.toLowerCase().includes(findInput.toLowerCase()||
        cliente.Nome.toLowerCase().includes(findInput.toLowerCase())||cliente.Cognome.toLowerCase().includes(findInput.toLowerCase()))))
      
        if(findInput === '' ||
            cliente.Nome_Azienda.toLowerCase().includes(findInput.toLowerCase()) ||
            cliente.Email.toLowerCase().includes(findInput.toLowerCase()) ||
            cliente.Numero.toLowerCase().includes(findInput.toLowerCase()) ||
            cliente.Indirizzo.toLowerCase().includes(findInput.toLowerCase()) ||
            cliente.Comune.toLowerCase().includes(findInput.toLowerCase()) ||
            cliente.Nome.toLowerCase().includes(findInput.toLowerCase()) ||
            cliente.Cognome.toLowerCase().includes(findInput.toLowerCase())){
          return true
        }else{
          return false
        }
      }

      const Requests = (props) => {


        function acceptReq(cli){
            //aggiunge cliente su collezione clienti
           db.collection('Users').doc(user.Email).collection('Clienti').doc(cli.email).set({
                email: cli.email,
                Att:0,
                Doc:0,
                Ele:0,
                Int:0,
                Man:0,
                Mapp:0
            }).then(()=>{
                //rimuove richiesta
                db.collection('Users').doc(user.Email).collection('Richieste').doc(cli.email).delete().then(()=>{
                    //aggiorna stato su off cliente
                    db.collection('Users').doc(cli.email).collection('Offritori').doc(user.Email).update({
                        Stato:'accepted'
                    }).then(()=>{
                         var arr2 = [...richieste]
            const index = arr2.findIndex((e)=>e.email===cli.email)
            arr2.splice(index,1)
            setRichieste(arr2)
                        reGet()
                        alert('Richiesta accettata con successo!')
                    })
                })
            })

          
        }
        function refuseReq(cli){
            db.collection('Users').doc(user.Email).collection('Richieste').doc(cli.email).delete().then(()=>{
                db.collection('Users').doc(cli.email).collection('Offritori').doc(user.Email).delete().then(()=>{
                    alert('Richiesta rifiutata con successo.')
                })
            })
        }
  
        return (
            <div className="pop-opac">
                <div className="popup" style={{backgroundColor:"white", height:'70vh',overflowY: "auto", width:'60vw', left:'20vw',top:'10vh'}} >
                <div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>
            <div style={{marginLeft:'auto',marginRight:'auto',width:'fit-content',alignItems:'center',display:'flex',flexDirection:'column',marginTop:'2vh'}}>
            <h4 className='page-title' style={{margin:0}}>VISUALIZZA RICHIESTE</h4>
            <h4 className='sub-text' style={{margin:0}}>Accetta le richieste per collegarti con i tuoi clienti Mysic</h4>

            </div>
            <div style={{width:'40vw',height:'50vh',marginLeft:'auto',marginRight:'auto',marginTop:'2vh',overflowY:'auto'}}>
            {richieste.length>0?richieste.map((req,i)=>(
                <div key={i} style={{display:'flex',alignItems:'center',border:'1px solid lightgrey',padding:'10px',borderRadius:'10px',justifyContent:'space-between'}}>
              <div>
              <Tooltip title={req.Nome_Azienda}>

<h4 className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {req.Nome_Azienda}
              </h4>
              </Tooltip>
              
            </div>
            <div>
              <Tooltip title={req.email}>

<h4 className="normal-text" style={{whiteSpace: 'nowrap',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'12vw'}}>
                {req.email}
              </h4>
              </Tooltip>
              
            </div>
            <div>
              <Tooltip title={req.Message}>

<h4 className="normal-text" style={{whiteSpace: 'nowrap',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'12vw'}}>
                {req.Message}
              </h4>
              </Tooltip>
              
            </div>
           {checkWriteS(user.Permissions)===true? <div >
                <Tooltip title='Accetta richiesta'>
                <IconButton onClick={()=>acceptReq(req)} style={{width:'fit-content'}}>
                 <CheckCircleIcon style={{color:'green'}}/>   
                </IconButton>
                </Tooltip>
                <Tooltip title='Rifiuta richiesta'>
                <IconButton onClick={()=>refuseReq()} style={{width:'fit-content'}}>
                 <CancelIcon style={{color:'red'}}/>   
                </IconButton>
                </Tooltip>
              
            </div>:null}
                </div>
            )):<h4 className='normal-text' style={{marginTop:'1rem'}}>Nessuna richiesta presente.</h4>}
            </div>
          
                </div>
            </div>
        )
      }

      const onSort = (columnKey) => {
        let direction = 'ascending';
        if (sortConfig.key === columnKey && sortConfig.direction === 'ascending') {
          direction = 'descending';
        }
        setSortConfig({ key: columnKey, direction });
      };
    
      const sortedCli = React.useMemo(() => {
        let sortableItems = [...clienti];
        if (sortConfig.key !== null) {
          sortableItems.sort((a, b) => {
            if (typeof a[sortConfig.key] === 'string') {
              return a[sortConfig.key].localeCompare(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else if (sortConfig.key === 'date') {
              return new Date(a[sortConfig.key]) - new Date(b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            }else if (sortConfig.key === 'number') {
              return (a[sortConfig.key] - b[sortConfig.key]) * (sortConfig.direction === 'ascending' ? 1 : -1);
            } else {
              return 0;
            }
          });
        }
        return sortableItems;
      }, [clienti, sortConfig]);

      function setCli(email){
        db.collection('Users').doc(user.Email).update({
          DefaultCli:email
        }).then(()=>{
          setDefaultCli(email)
          sessionStorage.clear()
        })
      }
      function unsetCli(){
        db.collection('Users').doc(user.Email).update({
          DefaultCli:''
        }).then(()=>{
          sessionStorage.clear()
          setDefaultCli(null)
        })
      }
      function sendEmailCheck(to,subject,text,ele){
        if(ele.Credentials===true){
            if (window.confirm("Hai già inviato le credenziali a questo cliente, sei sicuro di volerle inviare nuovamente?")) {
            sendEmail(to, subject, text);
            }
        }else{
          db.collection('Users').doc(ele.Email).update({
            Credentials:true
          })
            const updatedClienti = clienti.map(client => 
            client.Email === ele.Email ? { ...client, Credentials: true } : client
            );
            setClienti(updatedClienti);
          sendEmail(to,subject,text)
        }
      }


  return (
    <div className="background" style={{width:'100vw',height:'100vh'}}>
    <NavBar2/> 
    {triggerReq===true?<Requests setTrigger={setTriggerReq} trigger={triggerReq}/>:null}
   
    <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
    <div className="container-dash">
        <h4 className="page-title" style={{marginTop:'1.2rem'}}>CLIENTI</h4>
       
      </div>
      <Buttons/>
      <div className='box-shadow' style={{marginTop:'0.5rem',borderRadius:5,width:'75vw',backgroundColor:'white',height:'3rem',display:'flex',alignItems:'center'}}>
          <div style={{width:'10vw',height:'3rem',borderRight:'1px solid lightgrey',}}>
          <h4 className="normal-text" style={{marginLeft:'auto',marginRight:'auto',marginTop:'0.3rem',fontSize:'1rem',width:'fit-content'}}>
           {clienti.length}
          </h4>
          <h4 className="sub-text" style={{marginLeft:'auto',marginRight:'auto',fontSize:'0.8rem',width:'fit-content'}}>
          {'Clienti aggiunti'}
          </h4>
          </div>
          
             {/* */}
      
              <div  style={{marginLeft:'auto'}}>
                <input onChange={(e)=>
                  setFindInput(e.target.value.toLowerCase())}
                  className="input-style2" style={{width:'30rem',height:'1rem',fontWeight:'normal',fontFamily:'Poppins'}} type='text' placeholder={"Cerca cliente..."}></input>
                </div>            
          <div style={{marginLeft:10,marginRight:5}}>
          <Tooltip style={{width:'fit-content'}} title="Aggiorna lista">
                 <IconButton onClick={()=>reGet()} style={{backgroundColor:'#192bc2',width:'fit-content',height:'fit-content'}}  
                // onClick={()=>getEle(sedi,email)}
                 >
                 <LoopIcon style={{verticalAlign: 'middle', color: 'white',fontSize:'1rem'}}/>
                 </IconButton>
                 </Tooltip>
          </div>
          </div>
          <div style={{marginTop:10,borderRadius:5,width:'75vw',backgroundColor:'#14213d',height:'3rem',marginRight:'auto',display:'flex',alignItems:'center',boxShadow: 'rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px'}}>
          
          <div style={{borderRight:'1px solid lightgrey',width:'15vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('Nome_Azienda')}>
            <h4 className="header-table" >Ragione Sociale {sortConfig.key === 'Nome_Azienda' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',alignItems:'center',justifyContent:'center',cursor:'pointer'}} onClick={() => onSort('CurrentEmail')}>
            <h4 className="header-table" >Email {sortConfig.key === 'CurrentEmail' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Numero')}>
          <h4 className="header-table" >Telefono {sortConfig.key === 'Numero' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Indirizzo')}>
          <h4 className="header-table" >Indirizzo {sortConfig.key === 'Indirizzo' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Nome')}>
          <h4 className="header-table" >Nome {sortConfig.key === 'Nome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem',cursor:'pointer'}} onClick={() => onSort('Cognome')}>
          <h4 className="header-table" >Cognome {sortConfig.key === 'Cognome' ? (sortConfig.direction === 'ascending' ? '▲' : '▼') : '▼'}</h4>

          </div>
          <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'3rem'}}>
          <h4 className="header-table" >Azioni</h4>

          </div>
          </div>
          <div style={{overflowY:'auto',height:'74vh'}}>
          {sortedCli.length>0?sortedCli.map((ele,n)=>(
           renderCliente(ele)===true?
           <div key={n} className='table-row'>
            <div  style={{borderRight:'1px solid lightgrey',width:'15vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <div>
              <Tooltip title={ele.Nome_Azienda}>

<h4 className="medium-text" style={{whiteSpace: 'nowrap',color:'#192bc2',fontWeight:'bold',fontSize:'0.9rem',
overflow: 'hidden',
textOverflow: 'ellipsis',maxWidth:'9vw'}}>
                {ele.Nome_Azienda}
              </h4>
              </Tooltip>
                </div>
              
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <Tooltip title={ele.CurrentEmail}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.CurrentEmail}
              </h4>
              </Tooltip>
              
              </div> 
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div>
            <div style={{display:'flex',alignItems:'center'}}>
              <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
              {ele.Numero}
              </h4>
              
              </div>     
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <Tooltip title={ele.Indirizzo+' - '+ele.Comune}>
                <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Indirizzo} - {ele.Comune}
              </h4>
                </Tooltip>
            
            
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <Tooltip title={ele.Nome}>
                <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Nome}
              </h4>
                </Tooltip>
              </div>
            </div>
            <div style={{borderRight:'1px solid lightgrey',width:'10vw',height:'6rem',display:'flex',alignItems:'center'}}>
            <div style={{display:'flex',alignItems:'center'}}>
                <Tooltip title={ele.Cognome}>
                <h4 className="medium-text" style={{maxWidth:'9.3vw'}} >
                {ele.Cognome}
              </h4>
                </Tooltip>
            
            
              </div>
            </div>

            <div style={{width:'7vw',height:'6rem',display:'flex',alignItems:'center'}}>
              <Tooltip title="Imposta cliente di default. Verranno mostrati solamente i dati inerenti al cliente selezionato. Premi nuovamente per disattivare l'opzione.">
              <IconButton onClick={()=>defaultCli===ele.Email?unsetCli():setCli(ele.Email)} style={{width:'fit-content',marginLeft:'50x'}}>
              {defaultCli===ele.Email?<BookmarkIcon style={{color:'#192bc2'}}/>:<BookmarkBorderIcon style={{color:'#192bc2'}}/>}
              </IconButton>     
              </Tooltip>
              <Tooltip title="Invia credenziali">
                <IconButton style={{width:'fit-content',color:ele.Credentials?ele.Credentials===true?'green':'grey':'grey'}} onClick={()=>sendEmailCheck(ele.CurrentEmail,'Invio credenziali Mysic',`Ciao ${ele.Nome}, benvenuto/a in Mysic!\nDi seguito troverai le credenziali che ti permetteranno di accedere al tuo account.\nPotrai modificare le credenziali dalla pagina Impostazioni del tuo account.
                \n\nEmail: ${ele.CurrentEmail}\nPassword: ${ele.Password}\nLink di accesso: https://mysic.it/\n\nGrazie per la tua attenzione,\nIl team di Mysic: la tua sicurezza in Cloud, garantita 365 giorni all'anno.\n\nEmail generata automaticamente, non rispondere.`,ele)}>
                  <VpnKeyIcon/>
                </IconButton>
           
              </Tooltip>
              </div>
  
            </div>:null
           
          )): <h4 className='normal-text' style={{marginTop:'1rem'}}>Nessun cliente presente.</h4>}
        </div>
    </div>
    </div>
  )
}

export default Clienti