////////GENERALE


export function getScadenzeAnnualiOff(db,email,setMan,year,setManM,month,day,setManF){
    setMan([])
    setManM([])
    setManF([])
  
            db.collection('Manutenzioni').where('Operatore','==',email).get().then((manutenzioni)=>{
                manutenzioni.forEach((manz)=>{
                    if(manz.data().Data){
                        var arr = manz.data().Data.split('-')
    
                        if(parseInt(arr[0],10)===parseInt(year,10)){
                            setMan((prev)=>{
                              return [
                                ...prev,
                                  manz.data()
                                
                              ]
                            })
                          }
                            
                  if(parseInt(arr[0],10)===parseInt(year,10)&&parseInt(arr[1],10)-1===parseInt(month,10)){
                    setManM((prev)=>{
                      return [
                        ...prev,
                          manz.data()
                        
                      ]
                    })
                  }
      
                  if(parseInt(arr[0],10)===parseInt(year,10)&&parseInt(arr[1],10)-1>=parseInt(month,10)&&parseInt(arr[2],10)>=parseInt(day,10)){
                    setManF((prev)=>{
                      return [
                        ...prev,
                          manz.data()
                        
                      ]
                    })
                  }
                    }
                   
                    
                })
                
            
            })

}
export function getElementiOff(db,email,setElementi){
    setElementi([])
   db.collection('Elementi').where('Shared','==',email).get().then((elementi)=>{
    elementi.forEach((ele)=>{
        setElementi((prev)=>{
            return [
                ...prev,
                {
                    Id:ele.id,
                    ...ele.data()
                }
            ]
        })
    })
   })
}
export function getInterventiOff(db,email,setInterventi){
    setInterventi([])
   db.collection('Attività').where('Op_Email','==',email).get().then((interventi)=>{
    interventi.forEach((int)=>{
        setInterventi((prev)=>{
            return [
                ...prev,
                {
                    Id:int.id,
                    ...int.data()
                }
            ]
        })
    })
   })
}
export function getDocumentiOff(db,email,setDocumenti){
    setDocumenti([])
   db.collection('Documenti').where('Shared','==',email).get().then((documenti)=>{
    documenti.forEach((int)=>{
        setDocumenti((prev)=>{
            return [
                ...prev,
                {
                    Id:int.id,
                    ...int.data()
                }
            ]
        })
    })
   })
}
export function returnStato(stato,data){ //funzione che ritorna lo stato dell'intervento/manutenzione
    var string = data.split('-')
    var current = new Date()
    var month = null
   
    if(current.getMonth()<=11){
       month = current.getMonth()+1
    }else{
       month=1
    }   
    if(stato==='Conclusa'){
       return stato
    }else{
       if(parseInt(string[0],10)<current.getFullYear()){ //anno più basso di quello di scadenza
           return 'Scaduto'
       }else if(parseInt(string[2],10)<current.getDate()&&parseInt(string[1],10)===month){ //stesso mese giorno più basso
           return 'Scaduto'
       }else if(parseInt(string[1],10)<month&&parseInt(string[0],10)===current.getFullYear()){ //mese più basso stesso anno
           return 'Scaduto'
       }else{
           return stato
       }
    }
   }

export function getSediCliente(db,email,setSedi){
    setSedi([])

    db.collection('Users').doc(email).collection('Sedi').get().then((sedi)=>{
        sedi.forEach((sede)=>{
            setSedi((prev)=>{
                return [
                    ...prev,
                    {
                        Id:sede.id,
                        ...sede.data()
                    }
                ]
            })
        })
    })
}

////////ELEMENTI

export function getElementiClienti(db, email, setClienti, setElementi, setCategorie, setSottocategorie) {
    let cli = [];
    let cat = [];
    let sott = [];

    // Reset degli stati
    setClienti([]);
    setElementi([]);
    setCategorie([]);
    setSottocategorie([]);

    db.collection('Users').doc(email).collection('Clienti').get().then((clienti) => {
        let clientiDaScaricare = [];

        clienti.forEach((cliz) => {
            let clienteId = cliz.id;

            if (!sessionStorage.getItem(`elementiCliente_${clienteId}`)) {
                clientiDaScaricare.push(clienteId);
            } else {
                console.log(`Dati di ${clienteId} già in cache`);
                let datiCache = JSON.parse(sessionStorage.getItem(`elementiCliente_${clienteId}`));
                setClienti((prev) => [...prev, ...datiCache.clienti]);
                setElementi((prev) => [...prev, ...datiCache.elementi]);
                setCategorie((prev) => [...prev, ...datiCache.categorie]);
                setSottocategorie((prev) => [...prev, ...datiCache.sottocategorie]);
            }
        });

        if (clientiDaScaricare.length === 0) {
            console.log("Tutti i dati sono già in cache");
            return;
        }

        console.log("Scarico i dati per:", clientiDaScaricare);

        clientiDaScaricare.forEach((clienteId) => {
            db.collection('Users').doc(clienteId).get().then((cliDoc) => {
                if (!cliDoc.exists) return;

                let clienteData = { Id: cliDoc.id, ...cliDoc.data() };
                cli.push(clienteData);

                setClienti((prev) => [...prev, clienteData]);

                db.collection('Users').doc(email).collection('Clienti').doc(clienteId).collection('Elementi').get().then((elementi) => {
                    let elementiArray = [];
                    let categorieArray = [];
                    let sottocategorieArray = [];

                    elementi.forEach((elez) => {
                        db.collection('Elementi').doc(elez.data().ref).get().then((ele) => {
                            if (!ele.exists) return;

                            let elementoData = {
                                Id: ele.id,
                                Cliente: clienteId,
                                ...ele.data(),
                            };

                            // Se l'elemento esiste già nella cache, aggiorna solo i dati mancanti
                            let cachedData = JSON.parse(sessionStorage.getItem(`elementiCliente_${clienteId}`)) || { elementi: [] };
                            let index = cachedData.elementi.findIndex((e) => e.Id === ele.id);

                            if (index !== -1) {
                                cachedData.elementi[index] = { ...cachedData.elementi[index], ...elementoData };
                            } else {
                                cachedData.elementi.push(elementoData);
                            }

                            sessionStorage.setItem(`elementiCliente_${clienteId}`, JSON.stringify(cachedData));
                            elementiArray.push(elementoData);
                            setElementi((prev) => [...prev, elementoData]);

                            // Gestione delle categorie
                            if (!cat.find((e) => e.Nome === ele.data().Categoria && e.Cliente === clienteId)) {
                                let categoriaData = { Cliente: clienteId, Nome: ele.data().Categoria };
                                cat.push(categoriaData);
                                categorieArray.push(categoriaData);
                                setCategorie((prev) => [...prev, categoriaData]);
                            }

                            // Gestione delle sottocategorie
                            if (!sott.find((e) => e.Nome === ele.data().Macrocategoria && e.Cliente === clienteId)) {
                                let sottocategoriaData = {
                                    Cliente: clienteId,
                                    Nome: ele.data().Macrocategoria,
                                    Categoria: ele.data().Categoria,
                                };
                                sott.push(sottocategoriaData);
                                sottocategorieArray.push(sottocategoriaData);
                                setSottocategorie((prev) => [...prev, sottocategoriaData]);
                            }

                            // Aggiorna il cliente nel database se non ha il campo "Cliente" impostato
                            if (!ele.data().Cliente) {
                                db.collection('Elementi').doc(elez.data().ref).update({ Cliente: clienteId });
                            }
                        });
                    });

                    // Salva in cache i dati scaricati per il cliente
                    let elementiCliente = {
                        clienti: [clienteData],
                        elementi: elementiArray,
                        categorie: categorieArray,
                        sottocategorie: sottocategorieArray,
                    };

                    sessionStorage.setItem(`elementiCliente_${clienteId}`, JSON.stringify(elementiCliente));
                });
            });
        });
    });
}

export async function getEleCliente(db, email, cliente) {
    const clienti = [];
    const elementi = [];
    const categorie = [];
    const sottocategorie = [];

    console.log(email,cliente)

    const cliSnapshot = await db.collection('Users').doc(email).collection('Clienti').doc(cliente).get();
    const cliData = await db.collection('Users').doc(cliSnapshot.data().email).get();
  
    clienti.push({
      Id: cliData.id,
      ...cliData.data(),
    });
  
    const elementiSnapshot = await db
      .collection('Users')
      .doc(email)
      .collection('Clienti')
      .doc(cliData.id)
      .collection('Elementi')
      .get();
  
    for (const elez of elementiSnapshot.docs) {
      const eleSnapshot = await db.collection('Elementi').doc(elez.data().ref).get();
      if (eleSnapshot.exists) {
        const eleData = eleSnapshot.data();
  
        // Aggiorna il campo Cliente se mancante
        if (!eleData.Cliente) {
          await db.collection('Elementi').doc(elez.data().ref).update({
            Cliente: cliData.id,
          });
        }
  
        // Aggiungi elemento
        if (eleData.Nome) {
          elementi.push({
           
            ...eleData,
            Id: eleSnapshot.id,
            Cliente: cliData.id,
          });
        }
  
        // Aggiungi categoria
        if (!categorie.find((cat) => cat.Nome === eleData.Categoria && cat.Cliente === cliData.id)) {
          categorie.push({
            Cliente: cliData.id,
            Nome: eleData.Categoria,
          });
        }
  
        // Aggiungi sottocategoria
        if (!sottocategorie.find((sott) => sott.Nome === eleData.Macrocategoria && sott.Cliente === cliData.id)) {
          sottocategorie.push({
            Cliente: cliData.id,
            Nome: eleData.Macrocategoria,
            Categoria: eleData.Categoria,
          });
        }
      }
    }
  
    return { clienti, elementi, categorie, sottocategorie };
  }
export function getClientiSediCategorie(db,email,setClienti,setSedi,setCategorie){
    setClienti([])
    setSedi([])
    setCategorie([])

    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cliz)=>{
            db.collection('Users').doc(cliz.data().email).get().then((cli)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cli.id,
                            ...cli.data()
                        }
                    ]
                })
                db.collection('Users').doc(cli.id).collection('Sedi').get().then((sedi)=>{
                    sedi.forEach((sede)=>{
                        setSedi((prev)=>{
                            return [
                                ...prev,
                                {
                                    Id: sede.id,
                                    ...sede.data(),
                                    Cliente: cli.id
                                }
                            ]
                        })
                        db.collection('Users').doc(cli.id).collection('Sedi').doc(sede.id).collection('Categorie').get().then((categorie)=>{
                            categorie.forEach((cat)=>{
                                setCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: cat.id,
                                            ...cat.data(),
                                            Cliente: cli.id
                                        }
                                    ]
                                })
                            })
                        })
                    })
                })
            })
           
            
        })
    })
}  
export function getClientiSediSottocategorie(db,email,setClienti,setCategorie,setSottocategorie,arrAnti,arrEle,setAllCategorie){
    setClienti([])
    setCategorie([])
    setSottocategorie([])
    var arr = []
    var arr2 = []
    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cliz)=>{
            db.collection('Users').doc(cliz.data().email).get().then((cli)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cli.id,
                            ...cli.data()
                        }
                    ]
                })
                db.collection('Users').doc(cli.id).collection('Sedi').get().then((sedi)=>{
                    sedi.forEach((sede)=>{
                        db.collection('Users').doc(cli.id).collection('Sedi').doc(sede.id).collection('Categorie').get().then((categorie)=>{
                            categorie.forEach((cat)=>{

                                setAllCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: cat.id,
                                            ...cat.data(),
                                            Cliente: cli.id
                                        }
                                    ]
                                })

                                const index = arr.findIndex((e)=>e.Id===cat.id&&cli.id===e.Cliente)
                                if(arr.length===0||index===-1){
                                    arr.push({Id:cat.id, Cliente: cli.id,...cat.data()})
                                    setCategorie((prev)=>{
                                        return [
                                            ...prev,
                                            {
                                                Id: cat.id,
                                                ...cat.data(),
                                                Cliente: cli.id
                                            }
                                        ]
                                    })
                                }
                               
                                db.collection('Users').doc(cli.id).collection('Sedi').doc(sede.id).collection('Categorie').doc(cat.id).collection('Sottocategorie').get().then((sottocategorie)=>{
                                    sottocategorie.forEach((sottocat)=>{
                                        if(arrAnti.indexOf(sottocat.data().Nome)!==-1||arrEle.indexOf(sottocat.data().Nome)!==-1){
                                        }else{
                                            console.log('bella',sottocat.data())
                                            const index2 = arr2.findIndex((e)=>e.Id===sottocat.id&&cli.id===e.Cliente)
                                            if(arr2.length===0||index2===-1){
                                                arr2.push({Id:sottocat.id, Cliente: cli.id,...sottocat.data()})
                                            setSottocategorie((prev)=>{
                                                return [
                                                    ...prev,
                                                    {
                                                        Id: sottocat.id,
                                                        ...sottocat.data(),
                                                        Cliente: cli.id,
                                                        Categoria: cat.id
                                                    }
                                                ]
                                            })
                                        }
                                    }
                                        
                                    })
                                }) 
                            })
                        })
                    })
                })
            })
           
            
        })
    })
}  
export function getExtraSottocategorie(db,email,setSottocategorieExtra){
    db.collection('Users').doc(email).collection('SottocategorieAggiuntive').get().then((sotts)=>{
        sotts.forEach((sott)=>{
            console.log(sott.data())
            setSottocategorieExtra((prev)=>{
                return [
                    ...prev,
                    {
                        Id: sott.id,
                        ...sott.data()
                    }
                ]
            })
        })
    })
}

//////////////////DOCUMENTI//////////////////////////////

export function getDocumenti(db, email, setClienti, setCategorie, setCartelle, setDocumenti) {
    setClienti([]);
    setCategorie([]);
    setCartelle([]);
    setDocumenti([]);

    let categorie = [];
    let cartelle = [];

    // Controlliamo se ci sono dati già salvati in cache
    let clientiSalvati = JSON.parse(sessionStorage.getItem("clienti")) || [];

    db.collection('Users').doc(email).collection('Clienti').get().then((clienti) => {
        let clientiDaScaricare = [];

        clienti.forEach(cliz => {
            let clienteId = cliz.data().email;

            if (!sessionStorage.getItem(`documentiCliente_${clienteId}`)) {
                clientiDaScaricare.push(clienteId);
            } else {
                console.log(`Dati di ${clienteId} già in cache`);
                let datiCache = JSON.parse(sessionStorage.getItem(`documentiCliente_${clienteId}`));

                setClienti(prev => [...prev, ...datiCache.clienti]);
                setCategorie(prev => [...prev, ...datiCache.categorie]);
                setCartelle(prev => [...prev, ...datiCache.cartelle]);
                setDocumenti(prev => [...prev, ...datiCache.documenti]);
            }
        });

        if (clientiDaScaricare.length === 0) {
            console.log("Tutti i dati sono già in cache");
            return;
        }

        console.log("Scarico i dati per:", clientiDaScaricare);

        clientiDaScaricare.forEach(clienteId => {
            db.collection('Users').doc(clienteId).get().then((cli) => {
                if (!cli.exists) return;

                let clienteData = { Id: cli.id, ...cli.data() };
                setClienti(prev => [...prev, clienteData]);

                db.collection('Users').doc(email).collection('Clienti').doc(clienteId).collection('Documenti').get().then((documenti) => {
                    let documentiArray = [];
                    let categorieArray = [];
                    let cartelleArray = [];

                    documenti.forEach((docx) => {
                        db.collection('Documenti').doc(docx.data().ref).get().then((docz) => {
                            if (!docz.exists) return;

                            let documentoData = {
                                Id: docz.id,
                                ...docz.data(),
                                Versioni: docz.data().Versioni || null
                            };

                            documentiArray.push(documentoData);
                            setDocumenti(prev => [...prev, documentoData]);

                            // Gestione categorie
                            let categoriaKey = `${docz.data().Categoria}_${docz.data().Autore}_${docz.data().Sede}`;
                            if (!categorieArray.find(e => e.key === categoriaKey)) {
                                let categoriaData = {
                                    key: categoriaKey,
                                    Nome: docz.data().Categoria,
                                    Cliente: docz.data().Autore,
                                    Sede: docz.data().Sede
                                };
                                categorieArray.push(categoriaData);
                                setCategorie(prev => [...prev, categoriaData]);
                            }

                            // Gestione cartelle
                            let cartellaKey = `${docz.data().Cartella}_${docz.data().Autore}_${docz.data().Sede}_${docz.data().Categoria}`;
                            if (!cartelleArray.find(e => e.key === cartellaKey)) {
                                let cartellaData = {
                                    key: cartellaKey,
                                    Nome: docz.data().Cartella,
                                    Cliente: docz.data().Autore,
                                    Sede: docz.data().Sede,
                                    Categoria: docz.data().Categoria
                                };
                                cartelleArray.push(cartellaData);
                                setCartelle(prev => [...prev, cartellaData]);
                            }
                        });
                    });

                    // Salviamo i dati in cache
                    let documentiCliente = {
                        clienti: [clienteData],
                        documenti: documentiArray,
                        categorie: categorieArray,
                        cartelle: cartelleArray
                    };

                    sessionStorage.setItem(`documentiCliente_${clienteId}`, JSON.stringify(documentiCliente));
                });
            });
        });
    });
}


/*export function getDocumenti(db,email,setClienti,setCategorie,setCartelle,setDocumenti){
    setClienti([])
    setCategorie([])
    setCartelle([])
    setDocumenti([])

    var categorie = []
    var cartelle = []
    //////////SEDI/////////////////
    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach(cliz => {
            db.collection('Users').doc(cliz.data().email).get().then((cli)=>{
                setClienti((prev)=>{ 
                    return [
                        ...prev,
                        {
                            Id: cli.id,
                            ...cli.data(),
    
                        }
                    ]
                }) //aggiunge clienti
                  //////////DOCUMENTI///////////////////
            db.collection('Users').doc(email).collection('Clienti').doc(cli.id).collection('Documenti').get().then((documenti)=>{
                documenti.forEach((docx)=>{
                    db.collection('Documenti').doc(docx.data().ref).get().then((docz)=>{
                        if(docz.exists){
                            setDocumenti((prev)=>{
                                return [
                                    ...prev,
                                    {
                                        Id:docz.id,
                                        ...docz.data(),
                                        Versioni: docz.data().Versioni?docz.data().Versioni:null
                                    }
                                ]
                            })   
                            
                            var index = categorie.findIndex((e)=>e.Nome===docz.data().Categoria&&e.Cliente===docz.data().Autore&&e.Sede===docz.data().Sede)
                            var index2 = cartelle.findIndex((e)=>e.Nome===docz.data().Cartella&&e.Cliente===docz.data().Autore&&e.Sede===docz.data().Sede&&e.Categoria===docz.data().Categoria)
                            if(index===-1){
                                categorie.push({
                                    Nome: docz.data().Categoria,
                                    Cliente: docz.data().Autore,
                                    Sede: docz.data().Sede
                                })
                                setCategorie((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Nome: docz.data().Categoria,
                                            Cliente: docz.data().Autore,
                                            Sede: docz.data().Sede
                                        }
                                    ]
                                })
                            }
                            if(index2===-1){
                                cartelle.push({
                                    Nome: docz.data().Cartella,
                                    Cliente: docz.data().Autore,
                                    Sede: docz.data().Sede,
                                    Categoria: docz.data().Categoria
    
                                })
                                setCartelle((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Nome: docz.data().Cartella,
                                            Cliente: docz.data().Autore,
                                            Sede: docz.data().Sede,
                                            Categoria: docz.data().Categoria
                                        }
                                    ]
                                })
                            }
                            
                        }
                    })
                    
                })
            })
            })
            

          
        });
    })
} */
export async function getDocumentiCliente(db, email, cliente) {
    const clienti = [];
    const categorie = [];
    const cartelle = [];
    const documenti = [];
  
    const cliz = await db.collection('Users').doc(email).collection('Clienti').doc(cliente).get();
    const cli = await db.collection('Users').doc(cliz.data().email).get();
  
    clienti.push({
      Id: cli.id,
      ...cli.data(),
    });
  
    const docSnapshots = await db.collection('Users').doc(email).collection('Clienti').doc(cli.id).collection('Documenti').get();
  
    for (const docx of docSnapshots.docs) {
      const docz = await db.collection('Documenti').doc(docx.data().ref).get();
      if (docz.exists) {
        documenti.push({
          Id: docz.id,
          ...docz.data(),
          Versioni: docz.data().Versioni || null,
        });
  
        const categoriaIndex = categorie.findIndex(
          (e) => e.Nome === docz.data().Categoria && e.Cliente === docz.data().Autore && e.Sede === docz.data().Sede
        );
        if (categoriaIndex === -1) {
          categorie.push({
            Nome: docz.data().Categoria,
            Cliente: docz.data().Autore,
            Sede: docz.data().Sede,
          });
        }
  
        const cartellaIndex = cartelle.findIndex(
          (e) =>
            e.Nome === docz.data().Cartella &&
            e.Cliente === docz.data().Autore &&
            e.Sede === docz.data().Sede &&
            e.Categoria === docz.data().Categoria
        );
        if (cartellaIndex === -1) {
          cartelle.push({
            Nome: docz.data().Cartella,
            Cliente: docz.data().Autore,
            Sede: docz.data().Sede,
            Categoria: docz.data().Categoria,
          });
        }
      }
    }
  
    return { clienti, categorie, cartelle, documenti };
  }
export function getDocumento(db,id,setDocumento){
    db.collection('Documenti').doc(id).get().then((doc)=>{
        setDocumento(doc.data())
    })
}
export function getCartelle(db,cliente,sede,categoria,setCartelle){
    setCartelle([])
    db.collection('Users').doc(cliente).collection('Sedi').doc(sede).collection('Categorie').doc(categoria)
    .collection('Cartelle').get().then((cartelle)=>{
        cartelle.forEach((cartella)=>{
            setCartelle((prev)=>{
                return [
                    ...prev,
                    {
                        Id:cartella.id,
                        ...cartella.data()
                    }
                ]
            })
        })
    })
}


///////////////INTERVENTI/////////////////

export function getInterventi(db, email, setSedi, setClienti, setInterventi) {
    setInterventi([]);
    setClienti([]);
    setSedi([]);

    let sediUniche = [];
    let clientiScaricati = [];

    // Controllo se i clienti sono in cache
    let clientiSalvati = JSON.parse(sessionStorage.getItem("clienti")) || [];

    db.collection('Users').doc(email).collection("Clienti").get().then((clienti) => {
        let clientiDaScaricare = [];

        clienti.forEach((clientez) => {
            let clienteEmail = clientez.data().email;

            if (!sessionStorage.getItem(`interventiCliente_${clienteEmail}`)) {
                clientiDaScaricare.push(clienteEmail);
            } else {
                console.log(`Dati di ${clienteEmail} già in cache`);
                let datiCache = JSON.parse(sessionStorage.getItem(`interventiCliente_${clienteEmail}`));

                setClienti((prev) => [...prev, ...datiCache.clienti]);
                setInterventi((prev) => [...prev, ...datiCache.interventi]);
                setSedi((prev) => [...prev, ...datiCache.sedi]);
            }
        });

        if (clientiDaScaricare.length === 0) {
            console.log("Tutti i dati sono già in cache");
            return;
        }

        console.log("Scarico i dati per:", clientiDaScaricare);

        clientiDaScaricare.forEach((clienteEmail) => {
            db.collection('Users').doc(clienteEmail).get().then((cliente) => {
                if (!cliente.exists) return;

                let clienteData = { Id: cliente.id, ...cliente.data() };
                clientiScaricati.push(clienteData);
                setClienti((prev) => [...prev, clienteData]);

                db.collection('Users').doc(email).collection('Clienti').doc(cliente.id).collection('Attività').get().then((interventi) => {
                    let interventiArray = [];
                    let sediArray = [];

                    interventi.forEach((intervento) => {
                        db.collection('Attività').doc(intervento.data().ref).get().then((int) => {
                            if (!int.exists) return;

                            let interventoData = {
                                Id: int.id,
                                ...int.data(),
                                Stato: returnStato(int.data().Stato, int.data().Data),
                                Archiviato: int.data().Archiviato ? int.data().Archiviato : false,
                                Sott_Attivi: int.data().SottoAttività ? true : false
                            };

                            interventiArray.push(interventoData);
                            setInterventi((prev) => [...prev, interventoData]);

                            // Controllo per sedi uniche
                            if (!sediUniche.find((e) => e.Nome === int.data().Sede && e.Cliente === cliente.id)) {
                                let sedeData = { Nome: int.data().Sede, Cliente: cliente.id };
                                sediUniche.push(sedeData);
                                sediArray.push(sedeData);
                                setSedi((prev) => [...prev, sedeData]);
                            }

                            // Gestione sotto-attività
                            if (int.data().SottoAttività) {
                                db.collection("Attività").doc(int.id).collection("Attività").get().then((sottoManutenzioni) => {
                                    let arrz = [];
                                    let hasOpenSubTasks = false;

                                    sottoManutenzioni.forEach((sott) => {
                                        arrz.push(sott.data());
                                    });

                                    hasOpenSubTasks = arrz.some((e) => e.Stato !== 'Conclusa');

                                    setInterventi((prev) => prev.map((item) =>
                                        item.Id === int.id ? { ...item, Sott_Attivi: hasOpenSubTasks } : item
                                    ));
                                });
                            }
                        });
                    });

                    // Salva in cache i dati scaricati per il cliente
                    let interventiCliente = {
                        clienti: [clienteData],
                        interventi: interventiArray,
                        sedi: sediArray
                    };

                    sessionStorage.setItem(`interventiCliente_${clienteEmail}`, JSON.stringify(interventiCliente));
                });
            });
        });
    });
}


/*export function getInterventi(db,email,setSedi,setClienti,setInterventi){
    setInterventi([])
    setClienti([])
    setSedi([])

    var arr = []

    db.collection('Users').doc(email).collection("Clienti").get().then((clienti)=>{
        clienti.forEach((clientez)=>{
            db.collection('Users').doc(clientez.data().email).get().then((cliente)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cliente.id,
                            ...cliente.data()
                        }
                    ]
                })
                db.collection('Users').doc(email).collection('Clienti').doc(cliente.id).collection('Attività').get().then((interventi)=>{
                    interventi.forEach((intervento)=>{
                        db.collection('Attività').doc(intervento.data().ref).get().then((int)=>{
                            if(int.exists){
                                if(int.data().SottoAttività===true){
                                    var added = false
                        var control = false
                                    db
                                    .collection("Attività")
                                    .doc(int.id)
                                    .collection("Attività").get().then((sottoManutenzioni)=>{
                                        var c = 1
                                        var areNotClosed = null
                                        var arrz =[]

                                        if(sottoManutenzioni.docs.length>0){
                                            sottoManutenzioni.forEach((sott)=>{
                                                arrz.push(sott.data())

                                                if(c===sottoManutenzioni.docs.length){
                                                       areNotClosed = arrz.find((e)=>e.Stato!=='Conclusa')

                                                    console.log(areNotClosed)

                                                    if(areNotClosed){
                                                        areNotClosed=null

                                                        setInterventi((prev)=>{
                                                            return [
                                                                ...prev,
                                                                {
                                                                    Id: int.id,
                                                                    ...int.data(),
                                                                    Stato: returnStato(int.data().Stato,int.data().Data),
                                                                    Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                    Sott_Attivi: true
                                                                }
                                                            ]
                                                        })
                                                    }else{
                                                        setInterventi((prev)=>{
                                                            return [
                                                                ...prev,
                                                                {
                                                                    Id: int.id,
                                                                    ...int.data(),
                                                                    Stato: returnStato(int.data().Stato,int.data().Data),
                                                                    Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                    Sott_Attivi: false
                                                                }
                                                            ]
                                                        })
                                                    }
    
                                                }
                                            
                                                c++
                                              
                                            })
                                        }
                                 
                                    })
                                }else{
                                setInterventi((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Id: int.id,
                                            ...int.data(),
                                            Stato: returnStato(int.data().Stato,int.data().Data),
                                            Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                            Sott_Attivi: false

                                        }
                                    ]
                                })
                            
                            

                            var index = arr.findIndex((e)=>e.Nome===int.data().Sede&&int.data().Cliente===cliente.id)

                            if(index===-1){
                                setSedi((prev)=>{
                                    return [
                                        ...prev,
                                        {
                                            Nome: int.data().Sede,
                                            Cliente: int.data().Cliente
                                        }
                                    
                                    ]
                                })
                                arr.push({ Nome: int.data().Sede,
                                    Cliente: int.data().Cliente})

                            }
                            
                        }
                    }

                        })
                    })
                })

            })
            


        })
    })

}*/
export async function getInterventiCliente(db, email, cliente) {
    const clienti = [];
    const interventi = [];
    const sedi = [];
    
    // 1️⃣ Ottenere i dati del cliente
    const clienteSnap = await db.collection('Users').doc(email).collection("Clienti").doc(cliente).get();
    if (!clienteSnap.exists) return;
  
    const clienteData = await db.collection('Users').doc(clienteSnap.data().email).get();
    if (!clienteData.exists) return;
  
    clienti.push({
      Id: clienteData.id,
      ...clienteData.data(),
    });
  
    // 2️⃣ Recuperare gli interventi del cliente
    const interventiSnap = await db
      .collection('Users')
      .doc(email)
      .collection('Clienti')
      .doc(clienteData.id)
      .collection('Attività')
      .get();
  
    for (const intervento of interventiSnap.docs) {
      const intSnap = await db.collection('Attività').doc(intervento.data().ref).get();
      if (!intSnap.exists) continue;
  
      const intData = intSnap.data();
  
      // Se ha sotto-attività, verifica il loro stato
      if (intData.SottoAttività) {
        const sottoManutenzioniSnap = await db.collection("Attività").doc(intSnap.id).collection("Attività").get();
  
        const sottoManutenzioni = sottoManutenzioniSnap.docs.map(doc => doc.data());
        const hasOpenSubtasks = sottoManutenzioni.some(sub => sub.Stato !== 'Conclusa');
  
        interventi.push({
          Id: intSnap.id,
          ...intData,
          Stato: returnStato(intData.Stato, intData.Data),
          Archiviato: intData.Archiviato || false,
          Sott_Attivi: hasOpenSubtasks,
        });
  
      } else {
        // Se non ha sotto-attività, aggiungi direttamente
        interventi.push({
          Id: intSnap.id,
          ...intData,
          Stato: returnStato(intData.Stato, intData.Data),
          Archiviato: intData.Archiviato || false,
          Sott_Attivi: false,
        });
      }
  
      // 3️⃣ Aggiungere sedi se non già presenti
      if (!sedi.some(sede => sede.Nome === intData.Sede && sede.Cliente === clienteData.id)) {
        sedi.push({
          Nome: intData.Sede,
          Cliente: intData.Cliente,
        });
      }
    }
  
    return { clienti, interventi, sedi };
  }

///////////////SCADENZE/////////////////

export function getScadenze(db, email, setSedi, setClienti, setScadenze) {
    setScadenze([]);
    setClienti([]);
    setSedi([]);

    let clientiScaricati = [];
    let sediUniche = [];

    // Controllo la cache dei clienti
    let clientiSalvati = JSON.parse(sessionStorage.getItem("clienti")) || [];

    db.collection('Users').doc(email).collection("Clienti").get().then((clienti) => {
        let clientiDaScaricare = [];

        clienti.forEach((clientez) => {
            let clienteEmail = clientez.data().email;

            if (!sessionStorage.getItem(`scadenzeCliente_${clienteEmail}`)) {
                clientiDaScaricare.push(clienteEmail);
            } else {
                console.log(`Dati di ${clienteEmail} già in cache`);
                let datiCache = JSON.parse(sessionStorage.getItem(`scadenzeCliente_${clienteEmail}`));

                setClienti((prev) => [...prev, ...datiCache.clienti]);
                setScadenze((prev) => [...prev, ...datiCache.scadenze]);
                setSedi((prev) => [...prev, ...datiCache.sedi]);
            }
        });

        if (clientiDaScaricare.length === 0) {
            console.log("Tutti i dati sono già in cache");
            return;
        }

        console.log("Scarico i dati per:", clientiDaScaricare);

        clientiDaScaricare.forEach((clienteEmail) => {
            db.collection('Users').doc(clienteEmail).get().then((cliente) => {
                if (!cliente.exists) return;

                let clienteData = { Id: cliente.id, ...cliente.data() };
                clientiScaricati.push(clienteData);
                setClienti((prev) => [...prev, clienteData]);

                db.collection('Users').doc(email).collection('Clienti').doc(cliente.id).collection('Manutenzioni').get().then((scadenze) => {
                    let scadenzeArray = [];
                    let sediArray = [];

                    scadenze.forEach((scadenza) => {
                        db.collection('Manutenzioni').doc(scadenza.data().ref).get().then((int) => {
                            if (!int.exists) return;

                            let scadenzaData = {
                                Id: int.id,
                                ...int.data(),
                                Stato: returnStato(int.data().Stato, int.data().Data),
                                Archiviato: int.data().Archiviato ? int.data().Archiviato : false,
                                Sott_Attivi: int.data().SottoAttività ? true : false
                            };

                            scadenzeArray.push(scadenzaData);
                            setScadenze((prev) => [...prev, scadenzaData]);

                            // Controllo per sedi uniche
                            if (!sediUniche.find((e) => e.Nome === int.data().Sede && e.Cliente === cliente.id)) {
                                let sedeData = { Nome: int.data().Sede, Cliente: cliente.id };
                                sediUniche.push(sedeData);
                                sediArray.push(sedeData);
                                setSedi((prev) => [...prev, sedeData]);
                            }

                            // Gestione sotto-attività
                            if (int.data().SottoAttività) {
                                db.collection("Users")
                                    .doc(int.data().Cliente)
                                    .collection("Sedi")
                                    .doc(int.data().Sede)
                                    .collection("Manutenzioni")
                                    .doc(int.id)
                                    .collection("Manutenzioni").get().then((sottoManutenzioni) => {
                                        let arrz = [];
                                        let hasOpenSubTasks = false;

                                        sottoManutenzioni.forEach((sott) => {
                                            db.collection("Manutenzioni").doc(sott.data().ref).get().then((sottoManutenzione) => {
                                                if (sottoManutenzione.exists) {
                                                    arrz.push(sottoManutenzione.data());
                                                }

                                                if (arrz.length === sottoManutenzioni.docs.length) {
                                                    hasOpenSubTasks = arrz.some((e) => e.Stato !== 'Conclusa');

                                                    setScadenze((prev) => prev.map((item) =>
                                                        item.Id === int.id ? { ...item, Sott_Attivi: hasOpenSubTasks } : item
                                                    ));
                                                }
                                            });
                                        });
                                    });
                            }
                        });
                    });

                    // Salva in cache i dati scaricati per il cliente
                    let scadenzeCliente = {
                        clienti: [clienteData],
                        scadenze: scadenzeArray,
                        sedi: sediArray
                    };

                    sessionStorage.setItem(`scadenzeCliente_${clienteEmail}`, JSON.stringify(scadenzeCliente));
                });
            });
        });
    });
}



/*export function getScadenze(db,email,setSedi,setClienti,setScadenze){
    setScadenze([])
    setClienti([])
    setSedi([])

    var arr = []
    var arr2 = []

    db.collection('Users').doc(email).collection("Clienti").get().then((clienti)=>{
        clienti.forEach((clientez)=>{
            db.collection('Users').doc(clientez.data().email).get().then((cliente)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cliente.id,
                            ...cliente.data()
                        }
                    ]
                })
                db.collection('Users').doc(email).collection('Clienti').doc(cliente.id).collection('Manutenzioni').get().then((scadenze)=>{
                    scadenze.forEach((intervento)=>{
                        var arrz = []


                        db.collection('Manutenzioni').doc(intervento.data().ref).get().then((int)=>{
                            if(int.exists){
                                if(int.data().SottoAttività===true){
                                    var added = false
                                var control = false
                                    db.collection("Users")
                                    .doc(int.data().Cliente)
                                    .collection("Sedi")
                                    .doc(int.data().Sede)
                                    .collection("Manutenzioni")
                                    .doc(int.id)
                                    .collection("Manutenzioni").get().then((sottoManutenzioni)=>{
                                        var c = 1
                                        if(sottoManutenzioni.docs.length>0){
                                            sottoManutenzioni.forEach((sott)=>{


                                              db.collection("Manutenzioni").doc(sott.data().ref).get().then((sottoManutenzione)=>{
                                                arrz.push(sottoManutenzione.data())

                                                if(c===sottoManutenzioni.docs.length){
                                                    var areNotClosed = arrz.find((e)=>e.Stato!=='Conclusa')
                                                    console.log(areNotClosed)

                                                    if(areNotClosed){
                                                        setScadenze((prev)=>{
                                                            return [
                                                             ...prev,
                                                             {
                                                                 Id: int.id,
                                                                 ...int.data(),
                                                                 Stato: returnStato(int.data().Stato,int.data().Data),
                                                                 Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                 Sott_Attivi: true
                 
                                                             }
                                                            ]
                                                             
                                                         })
                                                    }else{
                                                        setScadenze((prev)=>{
                                                            return [
                                                             ...prev,
                                                             {
                                                                 Id: int.id,
                                                                 ...int.data(),
                                                                 Stato: returnStato(int.data().Stato,int.data().Data),
                                                                 Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                                                 Sott_Attivi: false
                 
                                                             }
                                                            ]
                                                             
                                                         })
                                                    }
    
                                                }
                                            
                                                c++


                                                 
                                              })
                                              
                                            })
                                        }
                                 
                                    })
                                }else{
                                    added=true
                                    setScadenze((prev)=>{
                                        return [
                                         ...prev,
                                         {
                                             Id: int.id,
                                             ...int.data(),
                                             Stato: returnStato(int.data().Stato,int.data().Data),
                                             Archiviato: int.data().Archiviato?int.data().Archiviato:false,
                                             Sott_Attivi: false

                                         }
                                        ]
                                         
                                     })
                                }
                                    var index = arr.findIndex((e)=>e.Nome===int.data().Sede&&int.data().Cliente===cliente.id)
    
                                    if(index===-1){
                                        setSedi((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Nome: int.data().Sede,
                                                    Cliente: int.data().Cliente
                                                }
                                            
                                            ]
                                        })
                                        arr.push({ Nome: int.data().Sede,
                                            Cliente: int.data().Cliente})
        
                                    }
                            }

                          
                            
                        

                        })
                    })
                })

            })
            


        })
    })

}*/

export async function getScadenzeCliente(db, email, cliente) {
    const clienti = [];
    const scadenze = [];
    const sedi = [];
  
    // 1️⃣ Ottenere i dati del cliente
    const clienteSnap = await db.collection('Users').doc(email).collection("Clienti").doc(cliente).get();
    if (!clienteSnap.exists) return;
  
    const clienteData = await db.collection('Users').doc(clienteSnap.data().email).get();
    if (!clienteData.exists) return;
  
    clienti.push({
      Id: clienteData.id,
      ...clienteData.data(),
    });
  
    // 2️⃣ Recuperare le scadenze del cliente
    const scadenzeSnap = await db
      .collection('Users')
      .doc(email)
      .collection('Clienti')
      .doc(clienteData.id)
      .collection('Manutenzioni')
      .get();
  
    for (const scadenza of scadenzeSnap.docs) {
      const intSnap = await db.collection('Manutenzioni').doc(scadenza.data().ref).get();
      if (!intSnap.exists) continue;
  
      const intData = intSnap.data();
  
      // Se ha sotto-attività, verifica il loro stato
      if (intData.SottoAttività) {
        const sottoManutenzioniSnap = await db
          .collection("Users")
          .doc(intData.Cliente)
          .collection("Sedi")
          .doc(intData.Sede)
          .collection("Manutenzioni")
          .doc(intSnap.id)
          .collection("Manutenzioni")
          .get();
  
        const sottoManutenzioni = sottoManutenzioniSnap.docs.map(doc => doc.data());
        const hasOpenSubtasks = sottoManutenzioni.some(sub => sub.Stato !== 'Conclusa');
  
        scadenze.push({
          Id: intSnap.id,
          ...intData,
          Stato: returnStato(intData.Stato, intData.Data),
          Archiviato: intData.Archiviato || false,
          Sott_Attivi: hasOpenSubtasks,
        });
  
      } else {
        // Se non ha sotto-attività, aggiungi direttamente
        scadenze.push({
          Id: intSnap.id,
          ...intData,
          Stato: returnStato(intData.Stato, intData.Data),
          Archiviato: intData.Archiviato || false,
          Sott_Attivi: false,
        });
      }
  
      // 3️⃣ Aggiungere sedi se non già presenti
      if (!sedi.some(sede => sede.Nome === intData.Sede && sede.Cliente === clienteData.id)) {
        sedi.push({
          Nome: intData.Sede,
          Cliente: intData.Cliente,
        });
      }
    }
  
    return { clienti, scadenze, sedi };
  }

export function getSottocategorieElementi(db,email,sede,categoria,setSottocategorie,setElementi){
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(categoria).collection('Sottocategorie').get().then((sottocategorie)=>{
        sottocategorie.forEach((sott)=>{
            setSottocategorie((prev)=>{
                return [
                    ...prev,
                    {
                        Id: sott.id,
                        ...sott.data()
                    }
                ]
            })
            db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Categorie').doc(categoria).collection('Sottocategorie')
            .doc(sott.id).collection('Elementi').get().then((elementi)=>{
                elementi.forEach((elemento)=>{
                    setElementi((prev)=>{
                        return [
                            ...prev,
                            {
                                Id:elemento.id,
                                ...elemento.data()
                            }
                        ]
                    })
                })
                
            })  
        })
    })
}

////////////MAPPE///////////////////

export function getSediClienti(db,email,setClienti,setSedi){

    setClienti([])
    setSedi([])

    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cli)=>{
            db.collection('Users').doc(cli.id).get().then((cliente)=>{
                setClienti((prev)=>{
                    return [
                        ...prev,
                        {
                            Id: cliente.data().Email,
                            ...cliente.data()
                        }
                    ]

                })
                db.collection('Users').doc(cliente.id).collection('Sedi').get().then((sedi)=>{
                    sedi.forEach((sede)=>{
                        setSedi((prev)=>{
                            return [
                                ...prev,
                                {Id: sede.id,
                                Cliente: cliente.data().Email,
                                ...sede.data()
                                }
                            ]
                        })
                    })
                })

            })
        })
    })
}

export function getPiani(db,email,sede,setPiani,setMappe){
    setPiani([])
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Piani').orderBy('Index','desc').get().then((piani)=>{
        piani.forEach((piano)=>{
            setPiani((prev)=>{
                return [
                    ...prev,
                    {
                        Id: piano.id,
                        ...piano.data()
                    }
                ]
            })
            db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Piani').doc(piano.id).collection('Mappe').get().then((mapz)=>{
                mapz.forEach((m)=>{
                    db.collection('Mappe').doc(m.data().ref).get().then((mappa)=>{
                        setMappe((prev)=>{
                            return [
                                ...prev,
                                {
                                    Id: mappa.id,
                                    ...mappa.data(),
                                    Piano: piano.id
                                }
                            ]
                        })
                    })
                })
            })
        })
    })  
}

export function getPiano(db,email,sede,piano,setPiano){
    db.collection('Users').doc(email).collection('Sedi').doc(sede).collection('Piani').doc(piano).get().then((p)=>{
        setPiano(p.data())

    })
}
export function getMappa(db,mappa_id,setMappa){
    db.collection('Mappe').doc(mappa_id).get().then((mappa)=>{
        setMappa(mappa.data())
    })
}
export function getElementiCliente(db,email,cliente,setElementi,setCategorie,setSottocategorie,setElementiPosizionati,mappa_id,sede_id){
    var cli = cliente
    var cat = []
    var sott = []
    setElementi([])
    setCategorie([])
    setSottocategorie([])

        db.collection('Users').doc(email).collection('Clienti').doc(cliente).collection('Elementi').get().then((elementi)=>{
            elementi.forEach((elez)=>{
                    db.collection('Elementi').doc(elez.data().ref).get().then((ele)=>{
                            //aggiunta elementi
                            if(ele.exists){
                                if(ele.data().Sede===sede_id){
                                    if(ele.data().Posizione&&ele.data().Mappa===mappa_id){
                                        setElementiPosizionati((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Id: ele.id,
                                                    Cliente: cli,
                                                    ...ele.data()
                                                }
                                            ]
                                           
                                        })
                                    }else if(!ele.data().Mappa&&!ele.data().Posizione){
                                        setElementi((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Id: ele.id,
                                                    Cliente: cli,
                                                    ...ele.data()
                                                }
                                            ]
                                        })
                                    }
                                    
                                    //aggiunta categoria
                                    var indexx = cat.findIndex((e)=>e.Nome===ele.data().Categoria&&e.Cliente===cli)
                                    if(indexx===-1){
                                        cat.push( {
                                            Cliente: cli,
                                            Nome: ele.data().Categoria
                                        })
                                        setCategorie((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Cliente: cli,
                                                    Nome: ele.data().Categoria,
                                                    
                                                }
                                                
                                            ]
                                        })
                                    }
                                        
                            
                                    //aggiunta sottocategoria
                                    var indexxx = sott.findIndex((e)=>e.Nome===ele.data().Macrocategoria&&e.Cliente===cli)
                                    if(indexxx===-1){
                                        sott.push( {
                                            Cliente: cli,
                                            Nome: ele.data().Macrocategoria,
                                            Categoria: ele.data().Categoria
                                        })
                                        setSottocategorie((prev)=>{
                                            return [
                                                ...prev,
                                                {
                                                    Cliente: cli,
                                                    Nome: ele.data().Macrocategoria,
                                                    Categoria: ele.data().Categoria,
                                                    Open: false,
                                                    Visualize:true,
                                                }
                                            ]
                                        })
                                    }
                                }
                            }
                            
                          
                            
                        })
                        
                    })
                })  
           
      
    }

//////////CLIENTI///////////////

export function getClienti(db,email,setClienti){
    setClienti([])
    db.collection('Users').doc(email).collection('Clienti').get().then((clienti)=>{
        clienti.forEach((cli)=>{
            db.collection('Users').doc(cli.id).get().then((cl)=>{
                setClienti((prev)=>{
                 return [
                    ...prev,
                    {
                        Id: cl.id,
                        CurrentEmail: cl.data().CurrentEmail?cl.data().CurrentEmail: cl.data().Email,
                        ...cl.data()
                    }
                 ]   
                })
            })
        })
    })
}
export function getRichieste(db,email,setRichieste){
    setRichieste([])
    db.collection('Users').doc(email).collection('Richieste').get().then((clienti)=>{
        clienti.forEach((cli)=>{
                setRichieste((prev)=>{
                 return [
                    ...prev,
                    {
                        Id: cli.id,
                        ...cli.data()
                    }
                 ]   
                })
        })
    })
}