import React , { useState, useEffect } from 'react'
import firebase from "firebase/app";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUtente,checkWriteS,} from "../../EngineClienti/engineClienti";
import Loading from '../../loading';
import CancelIcon from '@material-ui/icons/Cancel';
import { getClientiSediCategorie,getCartelle } from "../../EngineOffritore/engineOffritore";
import NavBar2 from '../../NavBar/NavBar2';
import { getSedeName } from '../../EngineClienti/engineClienti';
const AggiungiDocumentoOffritore = ({history}) => {

    const auth = firebase.auth();
    const db = firebase.firestore();
    const storage = firebase.storage();
    var storageRef = storage.ref();

    const [added,setAdded] = useState([]) //documenti aggiunti
    const [userM,setUserM] = useState(null)
    const [categorie, setCategorie] = useState([]); //lista categorie
    const [cartelle, setCartelle] = useState([]); //lista cartelle

    const [sedi,setSedi] = useState([])
    const [clienti,setClienti] = useState([])


    const [selectedCliente,setSelectedCliente] = useState('default')
    const [selectedSede,setSelectedSede] = useState('default')
    const [selectedCategoria,setSelectedCategoria] = useState('default')
    const [selectedCartella,setSelectedCartella] = useState('default')

    const [percentage,setPercentage] = useState(0)


    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUserM)
          }
        })
      },[auth])
    
      useEffect(()=>{
        if(userM!==null){
         getClientiSediCategorie(db,userM.Email,setClienti,setSedi,setCategorie)      
      }
    
      },[userM])

      useEffect(()=>{
        setSelectedSede('default')
       },[selectedCliente])

      useEffect(()=>{
       setSelectedCategoria('default')
      },[selectedSede])

      useEffect(()=>{
        if(selectedCategoria!==''&&selectedCategoria!=='default'){
          getCartelle(db,selectedCliente,selectedSede,selectedCategoria,setCartelle)
        }
      },[selectedCategoria])

      useEffect(()=>{
       setSelectedCategoria('default')
      },[selectedSede])

 
    
 function upload(e){

     uploadFile(e)
     
      } //funzione che triggera uploadFile quando si carica un documento
      
      async function calculate(files){
        var totalMB = parseFloat(0)
            var check = false
            var checked = 0
        for(let i=0;i<files.length;i++){

          const file = files[i];
          const sizeInMB = (file.size / (1024*1024)).toFixed(2);
          totalMB = parseFloat(totalMB) + parseFloat(sizeInMB)
          checked = checked + 1 
          console.log(checked, totalMB,files.length)
          if(checked===files.length){
            return totalMB           
          }

       
        }
      } //funziona che calcola il peso dei file caricati
      const uploadFile = async (e) => {
        //funzione per uploadare file su storage
            var files =  e.target.files;
    
            console.log(e.target.files)
            var c = 1
             const controlz =  await calculate(files)
              db.collection("Users")
            .doc(selectedCliente).get().then((docz)=>{

              const user = docz.data()
              const sedename = getSedeName(selectedSede,sedi)

              if(parseFloat(docz.data().Current_MB)+controlz<=docz.data().Max_MB){
                alert('Caricamento di '+files.length+' file nel seguente percorso: '+selectedSede+' -> '+selectedCategoria+' -> '+selectedCartella+' al cliente: '+user.Nome_Azienda+'. Premere OK e attendere il completamento')
                  
                db.collection("Users")
                .doc(user.Email).update({
                  Current_MB: parseFloat(docz.data().Current_MB)+controlz,
                  Current_Doc: firebase.firestore.FieldValue.increment(parseInt(files.length,10))
                }) //aggiorna cli

                db.collection("Users")
                .doc(userM.Email).update({
                  Current_Doc: firebase.firestore.FieldValue.increment(parseInt(files.length,10))
                }) //aggiorna man

                var documenti =  JSON.parse(sessionStorage.getItem('documenti'));
                var arr = []

                if(documenti){
                  arr=[...documenti]
                }

                for(let i=0;i<files.length;i++){
                  const docRef = db.collection("Documenti").doc().id
        
                  const file = files[i];
                  const ext = file.name.split('.').pop()
                  var sizeInMB = (file.size / (1024*1024)).toFixed(2);
    
                  const fileRef = storageRef.child(
                    user.Email +
                      "/" +
                      selectedSede +
                      "/" +
                      selectedCartella +
                      "/" +
                      docRef+'.'+ext
                  )
                  const uploadTask = storageRef.child(
                    user.Email +
                    "/" +
                    selectedSede +
                    "/" +
                    selectedCartella +
                    "/" +
                    docRef+'.'+ext
                  ).put(file); //percorso del file su storage
                  uploadTask.on('state_changed', 
                  (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    setPercentage(progress)
                    
                  }, 
                  (error) => {
                    // Handle unsuccessful uploads
                  }, 
                  // eslint-disable-next-line no-loop-func
                  () => {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  db.collection("Documenti")
                .doc(docRef).set({
                  //setta il file nella sua posizione nel db
                  //URL: url,
                  Scadenza: "No",
                  Sede: selectedSede,
                  SedeName: sedename,
                  NomeFile:files[i].name,
                  StorageRef: fileRef.fullPath,
                  URL:downloadURL,
                  Extension: ext.toString().toLowerCase(),
                  Size: sizeInMB,
                  Categoria:selectedCategoria,
                  Autore:user.Email,
                  Shared: userM.Email,
                  Act_ID: null,
                  Cartella: selectedCartella,
                  CaricatoDa: auth.currentUser.email,

                  //StorageRef: fileRef.fullPath
                }).then(()=>{
                  db.collection("Users")
                  .doc(user.Email)
                  .collection("Sedi")
                  .doc(selectedSede)
                  .collection("Categorie")
                  .doc(selectedCategoria)
                  .collection("Cartelle")
                  .doc(selectedCartella)
                  .collection("Documenti")
                  .doc(docRef).set({
                    ref:docRef
                  }) //aggiunge ref a cli

                  db.collection('Users').doc(userM.Email).collection('Clienti').doc(selectedCliente).collection('Documenti').doc(docRef).set({
                    ref: docRef
                  }) //aggiunge ref a man



                  arr.push({
                    Id: docRef,
                    NomeFile: files[i].name,
                    Scadenza: "No",
                    Sede: selectedSede,
                    SedeName: sedename,
                    Categoria: selectedCategoria,
                    Cartella:  selectedCartella,
                    StorageRef: fileRef.fullPath,
                    URL: downloadURL,
                    keyy: docRef,
                    Act_ID:null,
                    Extension: ext.toString().toLowerCase(),
                    Size: sizeInMB,
                    Autore:user.Email,
                    Shared: userM.Email,
                    CaricatoDa: auth.currentUser.email,
                  })
                  setAdded((prev)=>{
                  return [
                  ...prev,
                  {
                  
                    Id: docRef,
                    NomeFile: files[i].name,
                    Scadenza: "No",
                    Sede: selectedSede,
                    SedeName: sedename,
                    Categoria: selectedCategoria,
                    Cartella:  selectedCartella,
                    StorageRef: fileRef.fullPath,
                    URL: downloadURL,
                    keyy: docRef,
                    Act_ID:null,
                    Extension: ext.toString().toLowerCase(),
                    Size: sizeInMB,
                    Autore:user.Email,
                    Shared: userM.Email,
                    CaricatoDa: auth.currentUser.email,
                  
                  }
                  ]
                  })
                  

                  
                  if(c===files.length){
                    db.collection("Users")
                  .doc(user.Email)
                  .collection("Sedi")
                  .doc(selectedSede)
                  .collection("Categorie")
                  .doc(selectedCategoria).update({
                    Doc: firebase.firestore.FieldValue.increment(files.length)
                  }) //aggiorna n doc su categoria cli

                    db.collection("Users").doc(user.Email).collection("Sedi").doc(selectedSede).update({
                      Doc: firebase.firestore.FieldValue.increment(files.length)
                    }) //aggiorna n doc su sede cli

                    db.collection("Users")
                    .doc(user.Email)
                    .collection("Sedi")
                    .doc(selectedSede)
                    .collection("Categorie")
                    .doc(selectedCategoria)
                  .collection("Cartelle")
                  .doc(selectedCartella).update({
                    Doc: firebase.firestore.FieldValue.increment(files.length)
                  }) //aggiorna n doc su cartella cli

                  db.collection('Users').doc(userM.Email).collection('Clienti').doc(user.Email).update({
                    Doc: firebase.firestore.FieldValue.increment(files.length)
                  }) //aggiorna n doc su cliente man

                  sessionStorage.setItem('documenti', JSON.stringify(arr));
                  let cacheKey = `documentiCliente_${selectedCliente}`;
const cachedData = sessionStorage.getItem(cacheKey);
let parsedData;

// Se ci sono dati già salvati in cache, li recuperiamo
if (cachedData) {
  parsedData = JSON.parse(cachedData);

  // Estrai gli elementi esistenti
  const existingDocumenti = parsedData.documenti || [];
  const existingCategorie = parsedData.categorie || [];
  const existingSottoCategorie = parsedData.cartelle || [];

  // Unisci i documenti nuovi con quelli già presenti nella cache
  const updatedDocumenti = [...existingDocumenti, ...arr];

  // Aggiungi direttamente categoria e sottocategoria se non sono già presenti
  if (!existingCategorie.includes(selectedCategoria)) {
    existingCategorie.push(selectedCategoria);
  }

  if (!existingSottoCategorie.includes(selectedCartella)) {
    existingSottoCategorie.push(selectedCartella);
  }

  // Aggiorna la cache con i nuovi dati
  const updatedCacheData = {
    ...parsedData,
    documenti: updatedDocumenti,
    categorie: existingCategorie,
    cartelle: existingSottoCategorie
  };

  // Salva l'oggetto aggiornato nella cache
  sessionStorage.setItem(cacheKey, JSON.stringify(updatedCacheData));
} else {
  // Se non esiste una cache per i documenti, creane una nuova
  const newCacheData = {
    documenti: arr,
    categorie: [selectedCategoria],
    cartelle: [selectedCartella]
  };

  sessionStorage.setItem(cacheKey, JSON.stringify(newCacheData));
}

// Aggiorna la cache per le sottocategorie
let sottocategorieCache = [];
const cachedSott = sessionStorage.getItem('sottocategorie');

if (cachedSott) {
  sottocategorieCache = JSON.parse(cachedSott);
}

// Se la sottocategoria non è già presente, la aggiungiamo
if (sottocategorieCache.findIndex(e => e.Nome === selectedCartella) === -1) {
  sottocategorieCache.push({
    Nome: selectedCartella,
    Sede: selectedSede,
  });
}

sessionStorage.setItem('sottocategorie', JSON.stringify(sottocategorieCache));


                  
                  alert('Caricamento completato!')
                  setPercentage(0)
                  }
                  c= c+1
                })
                     
                    });
                  })
                }
              }else{
                alert('Hai terminato lo spazio fornito dal tuo piano Mysic, fai un upgrade del tuo piano per poter aggiungere ulteriori documenti.')
              }
            })
               
            
            
   
      }; //carica documento

      const [triggerCart,setTriggerCart] = useState(false) //trigger popup creazione cartella


      function CreateCart(props){

       

        const [selSede,setSelSede] = useState(selectedSede)
        const [selCat,setSelCat] = useState(selectedCategoria)
        const [selCart,setSelCart] = useState('')

      


        function addCartella(){

          try {
          
            db.collection("Users").doc(selectedCliente).collection("Sedi").doc(selSede).collection("Categorie").doc(selCat).collection("Cartelle").doc(selCart.trim())
            .get().then((esito)=>{
              if(!esito.exists){
           db.collection("Users")
              .doc(selectedCliente)
              .collection("Sedi")
              .doc(selSede)
              .collection("Categorie")
              .doc(selCat)
              .collection("Cartelle")
              .doc(selCart.trim())
              .set({
                //crea cartella su db
                Creatore: auth.currentUser.email,
                Nome: selCart.trim(),
                Doc: 0
              })
              .then(() => {
                var arr = [...props.cartelle]
                arr.push({Categoria: selCat,
                  Nome: selCart.trim(),
                  Creatore: auth.currentUser.email,
                  Doc: 0,
                  Id: selCart.trim(),
                Sede: selSede
                })

                  sessionStorage.setItem('cartelle', JSON.stringify(arr));

                  props.setCartelle(arr)
                alert('Cartella creata')
              })
              .catch((error) => {
                alert("Error adding document: ", error);
              });
            }else{
              alert("Errore: Cartella già esistente, riprova con un altro nome");
            }
            })
          } catch (e) {
            alert('Errore: Dare un nome alla cartella',e);
          }
        }



        return(
          <div className="pop-opac">
<div className="popup" style={{backgroundColor:"white", width:'40vw', left:'30vw', height:'50vh',top:'10rem'}}>
<div style={{textAlign:'left', width: 'fit-content'}}>
            <button
              className="cancelIconButton"
              
              onClick={() => props.setTrigger(false)}
            >
              <CancelIcon  style={{width: '15px', height: '15px'}}className="cancelIcon" />
            </button>
            </div>

            <div style={{marginLeft:'auto',width:'fit-content',marginRight:'auto',alignItems:'center',display:'flex',flexDirection:'column'}}>
            <h4 className="page-title" style={{margin:0}}>AGGIUNGI CARTELLA</h4>

                          <h4  className="sub-text-b">Seleziona sede:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selSede} onChange={(e) => {
            setSelSede(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                   if(sede.Cliente===selectedCliente){

                                    return <option key={n} value={sede.Id}>{sede.Comune}</option>
                                      }else{
                                        return null
                                      }
                              })}
        </select> 
{/*SELEZIONE CATEGORIA */}

{selectedSede!==''?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona categoria:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selCat} onChange={(e) => {
            setSelCat(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                {categorie && categorie.length > 0 && categorie.map((cat,n)=>{if(cat.Sede===selectedSede&&cat.Cliente===selectedCliente){
                                  return <option key={n} value={cat.Id}>{cat.Nome}</option>
                }else{
                  return null
                }
                              })}
        </select></div>:null}
          {/*NOME CARTELLA*/}
          <h4  className="sub-text-b" style={{marginTop:'1rem'}}>Nome cartella:</h4>
        <input className="input-style" style={{marginTop:'0.6rem',height:'1.5rem',paddingLeft:'5px'}} value={selCart} onChange={(e) => {
            setSelCart(e.target.value);
          }} placeholder='Nome cartella...'>
           
        </input>
        <br/>
        <button onClick={()=>addCartella()} className='main-button' style={{marginTop:'1rem'}}>Aggiungi</button>
        </div>
</div>
          </div>
        )
      }


  return (
    <div className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
      <NavBar2/>
      {triggerCart===true?<CreateCart setCartelle={setCartelle} cartelle={cartelle} setTrigger={setTriggerCart}/>:null}
    <div
   style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}
 >
    <div  style={{  width: "50vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <h4 className="page-title" style={{margin:0}}>AGGIUNGI DOCUMENTO {percentage!==0?percentage+'%':null}</h4>
        </div>
        <div style={{display:'flex'}}>
        <div style={{marginLeft:'3.5rem',marginTop:'2rem',width:'30rem'}}>

        <h4 className="sub-text-b" style={{}}>Seleziona il cliente a cui aggiungere i documenti:</h4>
        <select className="input-style"
        defaultValue={"default"}
        value={selectedCliente} onChange={(e) => {
            setSelectedCliente(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona cliente...
                </option>
                {clienti && clienti.length > 0 && clienti.map((cliente,n)=>{
                                  return <option key={n} value={cliente.Email}>{cliente.Nome_Azienda}</option>
                              })}
        </select>
                     {/*SELEZIONE SEDE */}

        {selectedCliente!=='default'?<div style={{marginTop:'1rem'}}>
        <h4  className="sub-text-b">Seleziona sede:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedSede} onChange={(e) => {
            setSelectedSede(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona sede...
                </option>
                {sedi && sedi.length > 0 && sedi.map((sede,n)=>{
                                   if(sede.Cliente===selectedCliente){

                                    return <option key={n} value={sede.Id}>{sede.Nome?sede.Nome!==''?sede.Nome:sede.Comune:sede.Comune}</option>
                                      }else{
                                        return null
                                      }
                              })}
        </select></div>:null 
        }
{/*SELEZIONE CATEGORIA */}

{selectedSede!=='default'?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona categoria:</h4>
        <select className="input-style" defaultValue={"default"}
        value={selectedCategoria} onChange={(e) => {
            setSelectedCategoria(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona categoria...
                </option>
                {categorie && categorie.length > 0 && categorie.map((cat,n)=>{if(cat.Sede===selectedSede&&cat.Cliente===selectedCliente){
                                  return <option key={n} value={cat.Id}>{cat.Nome}</option>
                }else{
                  return null
                }
                              })}
        </select></div>:null}
        {/*SELEZIONE CARTELLA */}
    
{selectedCategoria!==''&&selectedCategoria!=='default'?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona cartella:</h4>
<div style={{display:'flex',alignItems:'center'}}>
        <select className="input-style2" style={{width:'15rem',fontWeight:'normal'}} defaultValue={"default"}
        value={selectedCartella} onChange={(e) => {
            setSelectedCartella(e.target.value);
          }}>
            <option value={"default"} disabled>
                  Seleziona cartella...
                </option>
                {cartelle && cartelle.length > 0 && cartelle.map((cart,n)=>(
                                 <option key={n} value={cart.Id}>{cart.Nome}</option>
                )
                              )}
        </select>
        {checkWriteS(userM.Permissions)===true?<div style={{marginLeft:'1rem',marginTop:'auto'}}>
        <h4 className='text-buttons' onClick={()=>setTriggerCart(true)} >Aggiungi nuova cartella</h4></div>:null}
        </div></div>:null}

{/*SELEZIONE FILE */}
{selectedCartella!=='default'?<div style={{marginTop:'1rem'}}><h4  className="sub-text-b">Seleziona files:</h4>
       
            <input  multiple onChange={(e)=>upload(e)} style={{marginTop:'1rem'}} type='file'/>

       </div>:null}
        </div>
         
        <div className="box-shadow" style={{height:'50rem',width:'30rem',marginLeft:'auto',marginRight:'2rem',borderRadius:'10px'}}>
         <h4 className="title-medium" style={{marginTop:'1rem',marginLeft:'1rem'}}>Aggiunti di recente</h4>
         {added.length>0?added.map((add,n)=>(
         
            <div key={n} style={{marginTop:'1rem',marginLeft:'1rem'}}>
            {console.log(add)}
            <h4 className="normal-text">• {add.NomeFile}</h4>
            <h4 className="sub-text">{add.Categoria} - {add.Cartella}</h4>
           <h4 className="sub-text">Peso: {add.Size}MB</h4>
            </div>
         )):<h4 className="medium-text" style={{marginTop:'1rem',marginLeft:'1rem'}}>Nessun documento aggiunto di recente...</h4>}                           
        </div>
        </div>

    </div>
 </div>
  )
}

export default AggiungiDocumentoOffritore