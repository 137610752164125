import axios from 'axios';


export async function sendEmail(to, subject, text) {
    console.log(to,subject,text)
    const API_URL = process.env.NODE_ENV === "production"
    ? "https://api.mysic.it"
    : "http://localhost:3000";
    const emailData = {
        autenticationUser: "mySicSoftware",  // Inserisci il tuo username
        autenticationSecret: "b3GTDHxwbjpQ7C9wMFgg9pxQSzKqNHzA",  // Inserisci la tua password
        autenticationService: "mySicSoftware_mailSenderService",  // Nome del servizio che sta inviando l'email
        fromRecipient: "noreply@mysic.it",  // Email del mittente (autorizzata)
        toRecipients: to,  // Destinatario dell'email
        subject: subject,  // Oggetto dell'email
        text: text,  // Corpo del messaggio
        priority: 10  // Priorità (opzionale, default: 10)
    };

    try {
        const response = await axios.post(`${API_URL}/mailSenderService`, emailData, {
            headers: { 'Content-Type': 'application/json' }
        });

        if (response.status === 200 && response.data.status === "Success") {
            console.log("Email inviata con successo!");
            alert('Credenziali inviate con successo!')
        } else {
            console.log("Errore nell'invio dell'email:", response.data);
            alert("Errore durante l'invio. Riprova più tardi, se il problema persiste, contattare l'assistenza.")

        }
    } catch (error) {
        console.error("Errore nella richiesta:", error.response ? error.response.data : error.message);
    }
}

