import React,{useState,useEffect} from 'react'
import NavBar from '../../NavBar/NavBar'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import firebase from "firebase";
import { getUtente } from '../../EngineClienti/engineClienti';
import { Tooltip } from '@mui/material';

const AggiungiSede = ({history}) => {



    const [sede,setSede] = useState('')
    const [indirizzo,setIndirizzo] = useState('')
    const [numero,setNumero] = useState('')
    const [user,setUser] = useState(null)
    const [nome,setNome] = useState('')

    const db = firebase.firestore()
    const auth = firebase.auth()

    useEffect(()=>{
        auth.onAuthStateChanged(function (user) {
          if (user) {
            getUtente(db,auth,setUser)
          }
        })
      },[auth])

    function addSede(){

        if(sede!==''&&indirizzo!==''){
            if(window.confirm(`Vuoi davvero aggiungere la sede: ${nome===""?sede:nome}?`)){
                db.collection('Users').doc(user.Email).collection('Sedi').doc(sede).get().then((sedez)=>{
                    if(sedez.exists){
                        alert('Sede già aggiunta.')
                    }else{
                        db.collection('Users').doc(user.Email).collection('Sedi').doc().set({
                            Comune: sede,
                            Indirizzo: indirizzo,
                            Numero: numero,
                            Nome: nome,
                            Tag:'',
                            Doc: 0,
                            Ele:0,
                            Int:0,
                            Man:0,
                            Numero_Piani: 0
                        }).then(()=>{
                            db.collection('Users').doc(user.Email).update({
                                Sedi_Create: firebase.firestore.FieldValue.increment(1)
                            }).then(()=>{
                                alert('Sede aggiunta con successo.')
                                history.goBack()
                            })
                            
                        })
                    }
                })
            }
        }else{
            alert('Compila tutti i campi per procedere.')
        }
        
    }

  return (
    <div  className="background" style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
        <NavBar/>
        <div style={{height:'100vh',width:'85vw',marginLeft:'15vw'}}>
        <div  style={{  width: "30vw",display:'flex',marginTop:'1.2rem' }}>
          <button style={{marginBottom:'auto',marginTop:'1rem'}} onClick={() => history.goBack()}
            className="cancelIconButton"
            
          >
            <ArrowBackIcon
             className="back-button"
            />
          </button>
          <div>
          <h4 className="page-title" style={{margin:0}}>AGGIUNGI SEDE</h4>
          <h4
          className="sub-text"
        >
          Aggiungi le tue sedi
        </h4>
          </div>

        </div>
        <div style={{marginLeft:'3rem',marginTop:'10px',}}>
            <h4 className="normal-text">Comune della sede: </h4>
        
        <div style={{display:'flex',alignItems:'center'}}>
        <input className="input-style2" style={{height:'1.6rem',marginBottom:'auto',width:'15vw',marginTop:'5px'}} onChange={(e)=>setSede(e.target.value)} placeholder="Inserisci comune della sede..."></input>
        </div>
        <Tooltip title='Se non viene inserito alcun nome specifico, il nome della sede assegnato sarà il comune.'>
        <h4 className="normal-text" style={{marginTop:'15px',}}>Nome della sede (facoltativo): </h4>
        </Tooltip>
        <div style={{display:'flex',alignItems:'center'}}>
        <input className="input-style2" style={{height:'1.6rem',marginBottom:'auto',width:'15vw',marginTop:'5px'}} onChange={(e)=>setNome(e.target.value)} placeholder="Inserisci nome della sede..."></input>
        </div>
        <h4 className="normal-text" style={{marginTop:'15px',}}>Indirizzo della sede: </h4>
        
        <div style={{display:'flex',alignItems:'center'}}>
    
        <input className="input-style2" style={{height:'1.6rem',marginBottom:'auto',width:'15vw',marginTop:'5px'}} onChange={(e)=>setIndirizzo(e.target.value)} placeholder="Inserisci indirizzo della sede..."></input>
        </div>
        <h4 className="normal-text" style={{marginTop:'15px',}}>Numero telefonico della sede (facoltativo): </h4>
        
        <div style={{display:'flex',alignItems:'center'}}>
    
        <input className="input-style2" type='number' style={{height:'1.6rem',marginBottom:'auto',width:'15vw',marginTop:'5px'}} onChange={(e)=>setNumero(e.target.value)} placeholder="Inserisci numero della sede..."></input>
        </div>
        <button onClick={()=>addSede()} className="main-button" style={{marginTop:'2rem'}}>Aggiungi</button>
        </div>
      </div>
        </div>
  )
}

export default AggiungiSede